import { PieChart } from "@nef/charts";
import { Header, Loader, Section } from "@nef/core";
import { getColor } from "@nef/style-utils";
import { LimoLVCIndicator } from "components/limitMonitor/serviceIndicator";
import { useStandardTableDispatch } from "components/standardTable/standardTableContext";
import React, { useCallback } from "react";
import styled from "styled-components";
import { SelectOption } from "types";
import { abbreviateNumber } from "utils/js.utils";
import { StandardTables } from "wksConstants";
import { DATE_FILTER } from "./constant";
import { REJECT_CACHE_FILTER_BY, useRejectCacheDispatch } from "./rejectCache";
import { useRejectCountState } from "./rejectCountCache";

const CHART_HEIGHT_PX = 300;

const ServiceIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
`;

export const ArraySection = styled(Section)`
  ${props => `
    background-color: ${getColor("gray", 0)(props)};
    height: 100%;
    overflow: hidden;
    padding: 1rem !important;
    box-sizing: border-box;
    grid-column: span 1;
  `}
`;

export const ArrayWrapper = styled.div`
  padding-right: 1rem;
  box-sizing: border-box;
  overflow-y: scroll;
  height: calc(100% - 65px - 1rem);
`;

const ChartSection = styled(Section)`
  ${props => `
    background-color: ${getColor("gray", 0)(props)};
    padding: 1rem !important;
    box-sizing: border-box;
        grid-column: span 1;
  `}
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  height: 100%;
`;

const LoaderWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 0.5rem;
`;

const PieChartWrapper = styled.div`
  aspect-ratio: 1 / 1;
  width: ${CHART_HEIGHT_PX - 75}px;
`;

export const FlipCardHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoRejectsFiller = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoRejectsFillerJsx = <NoRejectsFiller>No Rejects Found</NoRejectsFiller>;

type PieChartData = { name: string; value: number }[];

type ProMonitorRejectPieChartProps = {
  pieChartData: PieChartData;
  totalRejectCount: number;
  selectedMpids: SelectOption[];
  selectedDateFilter: SelectOption;
};
export const ProMonitorRejectPieChart: React.FC<ProMonitorRejectPieChartProps> = ({
  pieChartData,
  totalRejectCount,
  selectedMpids,
  selectedDateFilter,
}) => {
  const tableDispatch = useStandardTableDispatch();
  const rejectCacheDispatch = useRejectCacheDispatch();
  const rejectCountState = useRejectCountState();

  const handlePieChartClick = useCallback(
    (event: React.MouseEvent, data: { name: string }) => {
      if (Array.isArray(selectedMpids) && selectedDateFilter?.value) {
        tableDispatch({
          type: "DESELECT_ALL_ROWS",
          payload: { table: StandardTables.PV_REJECTS },
        });
        rejectCacheDispatch({
          type: "START_POLLING",
          payload: {
            filter: {
              filterBy: REJECT_CACHE_FILTER_BY.MPID,
              criteria: {
                mpids: selectedMpids.map(mpid => mpid.value as string),
                rejectText: data.name,
                date: selectedDateFilter.value as DATE_FILTER,
              },
            },
          },
        });
      }
    },
    [rejectCacheDispatch, selectedDateFilter.value, selectedMpids, tableDispatch]
  );

  return (
    <ChartSection>
      <LoaderWrapper>
        <Loader isLoading={rejectCountState.isLoading}>
          {totalRejectCount === 0 ? NoRejectsFillerJsx : <></>}
          <ChartWrapper>
            <Header size={3}>
              <ServiceIndicatorWrapper>
                Price Rejects By Reason - {abbreviateNumber(totalRejectCount)}
                <LimoLVCIndicator
                  prefix={undefined}
                  id={"rej-reason-count-indicator"}
                  status={rejectCountState.status}
                />
              </ServiceIndicatorWrapper>
            </Header>
            <ChartContainer>
              <PieChartWrapper>
                <PieChart
                  data={pieChartData}
                  donut={false}
                  legend={false}
                  ratio="1:1"
                  onClick={handlePieChartClick}
                />
              </PieChartWrapper>
            </ChartContainer>
          </ChartWrapper>
        </Loader>
      </LoaderWrapper>
    </ChartSection>
  );
};
