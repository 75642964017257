import { NotificationHub } from "@nef/core";
import { ContextProviderProps, DispatchFn, DispatchFnSwitch } from "components/context/constants";
import ContextFactory from "components/context/factory";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { useCallback, useEffect, useReducer } from "react";
import { formatUrl } from "utils/js.utils";
import { Status } from "wksConstantsTS";
import { DATE_FILTER } from "./constant";

export enum REJECT_COUNT_FILTER_BY {
  NULL,
  ALL,
  MPID,
  SYMOL,
  MPID_SYMBOL,
  CLIENT_COUNTS,
  LITE_COUNTS,
  FINRA_COUNTS,
}

export enum FINRA_COUNT_TYPE {
  MPID = "mpidCounts",
  SYMBOL = "symbolCounts",
}

type REJECT_COUNT_FILTER =
  | null
  | { filterBy: REJECT_COUNT_FILTER_BY.MPID; criteria: string[]; date: DATE_FILTER }
  | {
      filterBy: REJECT_COUNT_FILTER_BY.MPID_SYMBOL;
      criteria: { [mpid: string]: string[] };
      date: DATE_FILTER;
    }
  | {
      filterBy: REJECT_COUNT_FILTER_BY.CLIENT_COUNTS;
      criteria: string[];
      date: DATE_FILTER;
    }
  | {
      filterBy: REJECT_COUNT_FILTER_BY.LITE_COUNTS;
      criteria: string[];
      date: DATE_FILTER;
    }
  | {
      filterBy: REJECT_COUNT_FILTER_BY.FINRA_COUNTS;
    };

export const getKeyFromMpidAndSymbol = (mpid: string, symbol: string) => {
  return `${mpid}|${symbol}`;
};

type Counts = {
  [symbol: string]: { priceCount: number; priceOverrideCount: number };
};

type FinraCountResponse = {
  mpidCounts: { counts: RejectCountItem[] };
  symbolCounts: { counts: RejectCountItem[] };
};

type LiteCounts = {
  [mpid: string]: Counts;
};

type FinraCounts = {
  mpidCounts: Counts;
  symbolCounts: Counts;
};

export type RejectCountState = {
  data: { [key: string]: RejectCountItem } | any;
  counts: Counts | LiteCounts;
  finraCounts: FinraCounts;
  offsets: { [key: string]: number };
  filter: REJECT_COUNT_FILTER;
  status: Status;
  makeInitialRequest: boolean;
  requestFailedCount: number;
  hasMadeInitialRequest: boolean;
  isPolling: boolean;
  isSummaryPolling: boolean;
  isRightPolling: boolean;
  isRequesting: boolean;
  timeStamp: number;
  instId: number;
  isLoading: boolean;
  requestAbort: boolean;
  abort: AbortController;
  requestTimer: NodeJS.Timeout | undefined;
};

export type RejectCountAction =
  | { type: "START_POLLING"; payload: { filter: REJECT_COUNT_FILTER } }
  | { type: "RESET_CACHE" }
  | { type: "STOP_POLLING" }
  | { type: "HANDLE_INITIAL_REQUEST" }
  | { type: "HANDLE_REQUEST_FAILED"; payload: number }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_REQUESTING"; payload: boolean }
  | { type: "SET_REQUEST_STATUS"; payload: Status }
  | { type: "SET_ABORT"; payload: AbortController }
  | { type: "SET_REQUEST_TIMER"; payload: NodeJS.Timeout }
  | { type: "SET_TIMESTAMP"; payload: number }
  | { type: "SET_INSTANCE_ID"; payload: number }
  | { type: "SET_DATA"; payload: any }
  | { type: "SET_COUNTS"; payload: Counts | LiteCounts }
  | { type: "SET_FINRA_COUNTS"; payload: FinraCounts }
  | { type: "SET_OFFSETS"; payload: { [key: string]: number } };

export type RejectCountItem = {
  priceCount: number;
  priceOverrideCount: number;
  key: string;
  timestamp: number;
};

const FACTORY = new ContextFactory<RejectCountState, RejectCountAction>();

const DEFAULT_STATE = {
  data: {},
  counts: {},
  finraCounts: { mpidCounts: {}, symbolCounts: {} },
  offsets: {},
  filter: null,
  status: Status.NO_STATUS,
  makeInitialRequest: false,
  requestFailedCount: 0,
  hasMadeInitialRequest: false,
  isPolling: false,
  isSummaryPolling: false,
  isRightPolling: false,
  isRequesting: false,
  timeStamp: 0,
  instId: 0,
  isLoading: false,
  requestAbort: false,
  abort: new AbortController(),
  requestTimer: undefined,
};

const [DISPATCH_CONTEXT, STATE_CONTEXT] = FACTORY.createContexts(DEFAULT_STATE);

const DISPATCH_FN_SWITCH: DispatchFnSwitch<RejectCountState, RejectCountAction> = (
  prevState: RejectCountState,
  action: RejectCountAction
) => {
  switch (action.type) {
    case "START_POLLING": {
      const { filter } = action.payload;
      prevState.abort.abort();
      clearTimeout(prevState.requestTimer);
      return {
        ...prevState,
        filter,
        isPolling: true,
        makeInitialRequest: true, //!prevState.hasMadeInitialRequest,
        hasMadeInitialRequest: false,
        isRequesting: false,
        isLoading: true,
        data: {},
        counts: {},
        finraCounts: { mpidCounts: {}, symbolCounts: {} },
        offsets: {},
      };
    }
    case "RESET_CACHE": {
      prevState.abort.abort();
      clearTimeout(prevState.requestTimer);
      return {
        ...DEFAULT_STATE,
      };
    }
    case "STOP_POLLING": {
      prevState.abort.abort();
      clearTimeout(prevState.requestTimer);
      return {
        ...prevState,
        isPolling: false,
      };
    }
    case "HANDLE_INITIAL_REQUEST": {
      return {
        ...prevState,
        makeInitialRequest: false,
        hasMadeInitialRequest: true,
      };
    }
    case "HANDLE_REQUEST_FAILED": {
      return {
        ...prevState,
        makeInitialRequest: true,
        hasMadeInitialRequest: false,
        requestFailedCount: action.payload,
      };
    }
    case "SET_LOADING": {
      return { ...prevState, isLoading: action.payload };
    }
    case "SET_REQUESTING": {
      return { ...prevState, isRequesting: action.payload };
    }
    case "SET_REQUEST_STATUS": {
      return { ...prevState, status: action.payload };
    }
    case "SET_ABORT": {
      return { ...prevState, abort: action.payload };
    }
    case "SET_REQUEST_TIMER": {
      return { ...prevState, requestTimer: action.payload };
    }
    case "SET_TIMESTAMP": {
      return { ...prevState, timeStamp: action.payload };
    }
    case "SET_INSTANCE_ID": {
      return { ...prevState, instId: action.payload };
    }
    case "SET_DATA": {
      return { ...prevState, data: action.payload };
    }
    case "SET_COUNTS": {
      return { ...prevState, counts: action.payload, isLoading: false };
    }
    case "SET_FINRA_COUNTS": {
      return { ...prevState, finraCounts: action.payload, isLoading: false };
    }
    case "SET_OFFSETS": {
      return { ...prevState, offsets: action.payload };
    }
    default:
      return { ...prevState };
  }
};

const DISPATCH_FN = FACTORY.createDispatchFn<RejectCountState, RejectCountAction>(
  DISPATCH_FN_SWITCH
);

interface RejectCountProviderProps extends ContextProviderProps {}

export const RejectCountProvider: React.FC<RejectCountProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<DispatchFn<RejectCountState, RejectCountAction>>(
    DISPATCH_FN,
    DEFAULT_STATE
  );

  const getCountData = useCallback(
    (isInitialData: boolean) => {
      const newOffsets = { ...state.offsets };
      const newData = { ...state.data };
      const newCounts = { ...state.counts };
      const newFinraCounts = { ...state.finraCounts };
      const getSuccessActions = (
        isInitialData: boolean,
        hasUpdate: boolean,
        timer: NodeJS.Timeout
      ) => {
        const actions: RejectCountAction[] = [
          {
            type: "SET_OFFSETS",
            payload: newOffsets,
          },
          {
            type: "SET_LOADING",
            payload: false,
          },
          {
            type: "SET_REQUEST_STATUS",
            payload: Status.SUCCESS,
          },
          {
            type: "SET_REQUEST_TIMER",
            payload: timer,
          },
        ];

        if (isInitialData) {
          actions.push({ type: "HANDLE_INITIAL_REQUEST" });
        }
        if (hasUpdate) {
          actions.push({
            type: "SET_DATA",
            payload: newData,
          });
          actions.push({
            type: "SET_COUNTS",
            payload: newCounts,
          });
          actions.push({
            type: "SET_FINRA_COUNTS",
            payload: newFinraCounts,
          });
        }
        return actions;
      };

      const getRejectCountsSuccess = (data: {
        timestamp: number;
        counts: { [mpid: string]: RejectCountItem[] };
      }) => {
        const timer = setTimeout(() => {
          dispatch({ type: "SET_REQUESTING", payload: true });
        }, 4000);

        let hasUpdate = false;
        Object.entries(data.counts).forEach(([mpid, rejectCounts]) => {
          hasUpdate = true;
          newOffsets[mpid] = data.timestamp;
          rejectCounts.forEach(count => {
            newData[getKeyFromMpidAndSymbol(mpid, count.key)] = count;
            if (newCounts[count.key] === undefined) {
              newCounts[count.key] = {
                priceCount: count.priceCount || 0,
                priceOverrideCount: count.priceOverrideCount || 0,
              };
            } else {
              (newCounts as Counts)[count.key].priceCount +=
                count.priceCount -
                (state.data[getKeyFromMpidAndSymbol(mpid, count.key)]?.priceCount || 0);
              (newCounts as Counts)[count.key].priceOverrideCount +=
                count.priceOverrideCount -
                (state.data[getKeyFromMpidAndSymbol(mpid, count.key)]?.priceOverrideCount || 0);
            }
            if (
              newCounts[count.key].priceCount === 0 &&
              newCounts[count.key].priceOverrideCount === 0
            ) {
              delete newCounts[count.key];
            }
          });
        });
        dispatch(getSuccessActions(isInitialData, hasUpdate, timer));
      };

      const getLiteCountsSuccess = (data: {
        timestamp: number;
        counts: { [mpid: string]: RejectCountItem[] };
      }) => {
        const timer = setTimeout(() => {
          dispatch({ type: "SET_REQUESTING", payload: true });
        }, 4000);

        let hasUpdate = false;
        Object.entries(data.counts).forEach(([mpid, rejectCounts]) => {
          hasUpdate = true;
          newOffsets[mpid] = data.timestamp;
          rejectCounts.forEach(count => {
            newData[getKeyFromMpidAndSymbol(mpid, count.key)] = count;
            if ((newCounts as LiteCounts)[mpid] === undefined) {
              (newCounts as LiteCounts)[mpid] = {
                [count.key]: {
                  priceCount: count.priceCount || 0,
                  priceOverrideCount: count.priceOverrideCount || 0,
                },
              };
            } else {
              (newCounts as LiteCounts)[mpid][count.key] = {
                priceCount: count.priceCount || 0,
                priceOverrideCount: count.priceOverrideCount || 0,
              };
            }
            // delete if priceOverrideCount is 0; LITE view doesn't care about priceCount
            if ((newCounts as LiteCounts)[mpid][count.key].priceOverrideCount === 0) {
              delete (newCounts as LiteCounts)[mpid][count.key];
              if (Object.keys((newCounts as LiteCounts)[mpid]).length === 0) {
                delete (newCounts as LiteCounts)[mpid];
              }
            }
          });
        });
        dispatch(getSuccessActions(isInitialData, hasUpdate, timer));
      };

      const getRejectCountsError = () => {
        const actions: RejectCountAction[] = [
          {
            type: "SET_LOADING",
            payload: false,
          },
          { type: "HANDLE_REQUEST_FAILED", payload: state.requestFailedCount + 1 },
        ];
        actions.push({
          type: "SET_REQUEST_STATUS",
          payload: Status.ERROR,
        });
        if (state.status !== Status.ERROR) {
          NotificationHub.send("danger", "Error getting Price Validation Reject counts");
        }
        dispatch(actions);
      };

      const getPorAbortCb = () => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
      };

      const getFinraCountsSuccess = (data: FinraCountResponse) => {
        const timer = setTimeout(() => {
          dispatch({ type: "SET_REQUESTING", payload: true });
        }, 4000);

        let hasUpdate = false;
        // mpid counts
        data[FINRA_COUNT_TYPE.MPID].counts.forEach(count => {
          hasUpdate = true;
          newOffsets[FINRA_COUNT_TYPE.MPID] = Math.max(
            newOffsets[FINRA_COUNT_TYPE.MPID] || 0,
            count.timestamp
          );
          if (newData[FINRA_COUNT_TYPE.MPID] === undefined) {
            newData[FINRA_COUNT_TYPE.MPID] = {};
          }
          newData[FINRA_COUNT_TYPE.MPID][count.key] = count;
          if (newFinraCounts[FINRA_COUNT_TYPE.MPID][count.key] === undefined) {
            newFinraCounts[FINRA_COUNT_TYPE.MPID][count.key] = {
              priceCount: count.priceCount || 0,
              priceOverrideCount: count.priceOverrideCount || 0,
            };
          } else {
            newFinraCounts[FINRA_COUNT_TYPE.MPID][count.key].priceCount +=
              count.priceCount - (state.data[FINRA_COUNT_TYPE.MPID][count.key]?.priceCount || 0);
            newFinraCounts[FINRA_COUNT_TYPE.MPID][count.key].priceOverrideCount +=
              count.priceOverrideCount -
              (state.data[FINRA_COUNT_TYPE.MPID][count.key]?.priceOverrideCount || 0);
          }
          if (
            newFinraCounts[FINRA_COUNT_TYPE.MPID][count.key].priceCount === 0 &&
            newFinraCounts[FINRA_COUNT_TYPE.MPID][count.key].priceOverrideCount === 0
          ) {
            delete newFinraCounts[FINRA_COUNT_TYPE.MPID][count.key];
          }
        });

        // symbol counts
        data[FINRA_COUNT_TYPE.SYMBOL].counts.forEach(count => {
          hasUpdate = true;
          newOffsets[FINRA_COUNT_TYPE.SYMBOL] = Math.max(
            newOffsets[FINRA_COUNT_TYPE.SYMBOL] || 0,
            count.timestamp
          );
          if (newData[FINRA_COUNT_TYPE.SYMBOL] === undefined) {
            newData[FINRA_COUNT_TYPE.SYMBOL] = {};
          }
          newData[FINRA_COUNT_TYPE.SYMBOL][count.key] = count;
          if (newFinraCounts[FINRA_COUNT_TYPE.SYMBOL][count.key] === undefined) {
            newFinraCounts[FINRA_COUNT_TYPE.SYMBOL][count.key] = {
              priceCount: count.priceCount || 0,
              priceOverrideCount: count.priceOverrideCount || 0,
            };
          } else {
            newFinraCounts[FINRA_COUNT_TYPE.SYMBOL][count.key].priceCount +=
              count.priceCount - (state.data[FINRA_COUNT_TYPE.SYMBOL][count.key]?.priceCount || 0);
            newFinraCounts[FINRA_COUNT_TYPE.SYMBOL][count.key].priceOverrideCount +=
              count.priceOverrideCount -
              (state.data[FINRA_COUNT_TYPE.SYMBOL][count.key]?.priceOverrideCount || 0);
          }
          if (
            newFinraCounts[FINRA_COUNT_TYPE.SYMBOL][count.key].priceCount === 0 &&
            newFinraCounts[FINRA_COUNT_TYPE.SYMBOL][count.key].priceOverrideCount === 0
          ) {
            delete newFinraCounts[FINRA_COUNT_TYPE.SYMBOL][count.key];
          }
        });
        dispatch(getSuccessActions(isInitialData, hasUpdate, timer));
      };

      const abortController = new AbortController();
      // setAbort(abortController);
      dispatch({ type: "SET_ABORT", payload: abortController });
      if (state.filter !== null) {
        switch (state.filter.filterBy) {
          case REJECT_COUNT_FILTER_BY.CLIENT_COUNTS: {
            const mpidToSymbols = state.filter.criteria.reduce((acc: any, mpid: string) => {
              acc[mpid] = { "*": state.offsets[mpid] || 0 };
              return acc;
            }, {} as any);
            doFetchWrapper(
              formatUrl(process.env.REACT_APP_URL_PVR_REJECT_CACHE, "clientCountsByMpid"),
              {
                method: "post",
                mode: "cors",
                signal: abortController.signal,
                headers: getHeaders(),
                body: JSON.stringify({
                  mpidToSymbols,
                  date: state.filter.date,
                }),
              },
              getRejectCountsSuccess,
              getRejectCountsError,
              getPorAbortCb
            );
            break;
          }
          case REJECT_COUNT_FILTER_BY.LITE_COUNTS: {
            const mpidToSymbols = state.filter.criteria.reduce((acc: any, mpid: string) => {
              acc[mpid] = { "*": state.offsets[mpid] || 0 };
              return acc;
            }, {} as any);
            doFetchWrapper(
              formatUrl(process.env.REACT_APP_URL_PVR_REJECT_CACHE, "clientCountsByMpid"),
              {
                method: "post",
                mode: "cors",
                signal: abortController.signal,
                headers: getHeaders(),
                body: JSON.stringify({
                  mpidToSymbols,
                  date: state.filter.date,
                }),
              },
              getLiteCountsSuccess,
              getRejectCountsError,
              getPorAbortCb
            );
            break;
          }
          case REJECT_COUNT_FILTER_BY.FINRA_COUNTS:
            // const mpids = state.filter.type.reduce((acc: any, mpid: string) => {
            //   acc[mpid] = { "*": state.offsets[mpid] || 0 };
            //   return acc;
            // }, {} as any);
            doFetchWrapper(
              formatUrl(process.env.REACT_APP_URL_PVR_REJECT_CACHE, "finraCounts"),
              {
                method: "post",
                mode: "cors",
                signal: abortController.signal,
                headers: getHeaders(),
                body: JSON.stringify({
                  mpidTimestamp: state.offsets[FINRA_COUNT_TYPE.MPID] || 0,
                  symbolTimestamp: state.offsets[FINRA_COUNT_TYPE.SYMBOL] || 0,
                }),
              },
              getFinraCountsSuccess,
              getRejectCountsError,
              getPorAbortCb
            );
            break;
        }
      }
    },
    [
      state.offsets,
      state.data,
      state.counts,
      state.finraCounts,
      state.filter,
      state.requestFailedCount,
      state.status,
    ]
  );

  // -- initial request --
  // should only have to run once as long as timeStamp
  // is persisted throughout the entire session
  useEffect(() => {
    if (state.makeInitialRequest && state.isPolling) {
      if (state.requestFailedCount > 0) {
        const timer = setTimeout(() => {
          getCountData(true);
        }, 4000);
        dispatch({ type: "SET_REQUEST_TIMER", payload: timer });
      } else {
        dispatch({ type: "SET_LOADING", payload: true });
        getCountData(true);
      }
    }
  }, [state.makeInitialRequest, getCountData, state.requestFailedCount, state.isPolling]);

  useEffect(() => {
    if (state.isPolling && state.isRequesting && !state.requestAbort) {
      dispatch({ type: "SET_REQUESTING", payload: false });
      getCountData(false);
    } else if (!state.isPolling) {
      dispatch({ type: "SET_REQUEST_STATUS", payload: Status.NO_STATUS });
    }
  }, [state.isPolling, state.isRequesting, state.requestAbort, getCountData]);

  return (
    <DISPATCH_CONTEXT.Provider value={dispatch}>
      <STATE_CONTEXT.Provider value={state}>{children}</STATE_CONTEXT.Provider>
    </DISPATCH_CONTEXT.Provider>
  );
};

export const useRejectCountDispatch =
  FACTORY.createContextHook<React.Dispatch<RejectCountAction | RejectCountAction[]>>(
    DISPATCH_CONTEXT
  );
export const useRejectCountState = FACTORY.createContextHook<RejectCountState>(STATE_CONTEXT);
