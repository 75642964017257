import React, { useMemo } from "react";
import styled from "styled-components";

const List = styled.ul`
  margin-left: 1.5rem;
  padding: 0;
`;

interface SubtitleListProps {
  messages: string[];
  maxNumShown?: number;
  additionalMessageOnMax?: string;
}
export const SubtitleList: React.FC<SubtitleListProps> = ({
  messages,
  maxNumShown,
  additionalMessageOnMax,
}) => {
  const ListItems = useMemo(() => {
    const items = [];
    for (let i = 0; i < messages.length; i++) {
      if (
        maxNumShown === undefined ||
        i < maxNumShown ||
        (i === maxNumShown && i === messages.length - 1 && additionalMessageOnMax !== undefined)
      ) {
        items.push(<li>{messages[i]}</li>);
      } else {
        if (additionalMessageOnMax !== undefined && i !== messages.length - 1) {
          items.push(<li>{additionalMessageOnMax}</li>);
        }
        break;
      }
    }
    return items;
  }, [additionalMessageOnMax, maxNumShown, messages]);

  return <List>{ListItems}</List>;
};
