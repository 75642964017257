import { FORM_KEY, FieldNames, Forms, SelectOptions } from "components/fields/fieldConstants";
import { KeycloakRoles } from "components/user/keycloakRoles";
import { useUserContext } from "components/user/context";
import React, { createContext, useContext, useEffect, useReducer, useRef, useState } from "react";
import { SelectOption } from "types";
import { RequestResult, RiskStatus } from "wksConstants";
import { MPIDFormOptionMap } from "./constants";
import { addPrefixToField, formatUrl } from "utils/js.utils";
import { PTRA_AGU_ENTITY, PTRA_FIELD_PREFIX } from "components/settings/ptra/fields";
import { getHeaders } from "keycloak";
import { NotificationHub } from "@nef/core";
import { FieldOptionState, useFieldOptionDispatch } from "components/fields/loadingContext";
import { formDispatch, useFormDispatch } from "components/form";
import { Form } from "components/form/constants";

export enum USER_MPIDS_ATTRIBUTE {
  WORKX_TRADE_REPORTING_MPIDS = "WORKX_TRADE_REPORTING_MPIDS",
  WORKX_PTR_MPIDS = "WORKX_PTR_MPIDS",
  WORKX_REALTIME_STATISTICS_MPIDS = "WORKX_REALTIME_STATISTICS_MPIDS",
  UI_TRADE_REPORTING_ACTION_MPIDS = "UI_TRADE_REPORTING_ACTION_MPIDS",
}

export type MPIDOptionsAction =
  | {
      type: "SET_USER_MPID_OPTIONS";
      payload: {
        trMpidOptions: SelectOption[];
        stMpidOptions: SelectOption[];
      };
    }
  | {
      type: "SET_CLEAR_CORR_MPID_OPTIONS";
      payload: {
        trClearCorrOptions: SelectOption[];
        activeRiskMpidOptions: SelectOption[];
        activeTransitionalRiskMpidOptions: SelectOption[];
      };
    };

export interface MPIDOptionsState {
  userMPIDOptions: {
    isReady: boolean;
    trMpidOptions: SelectOption[];
    stMpidOptions: SelectOption[];
  };
  clearCorrMPIDOptions: {
    isReady: boolean;
    trClearCorrOptions: SelectOption[];
    activeRiskMpidOptions: SelectOption[];
    activeTransitionalRiskMpidOptions: SelectOption[];
  };
  loadingMap: { [fieldName: string]: Set<string> };
}

const defaultState: MPIDOptionsState = {
  userMPIDOptions: { isReady: false, trMpidOptions: [], stMpidOptions: [] },
  clearCorrMPIDOptions: {
    isReady: false,
    trClearCorrOptions: [],
    activeRiskMpidOptions: [],
    activeTransitionalRiskMpidOptions: [],
  },
  loadingMap: {},
};

const userMPIDsDispatch = createContext<React.Dispatch<MPIDOptionsAction | MPIDOptionsAction[]>>(
  () => null
);
userMPIDsDispatch.displayName = "userMPIDsDispatch";
export const useMPIDOptionsDispatch = () => useContext(userMPIDsDispatch);

const userMPIDsContext = createContext<
  [MPIDOptionsState, React.Dispatch<MPIDOptionsAction | MPIDOptionsAction[]>]
>([defaultState, () => null]);
userMPIDsContext.displayName = "userMPIDsContext";
export const useMPIDOptionsContext = () => useContext(userMPIDsContext);

const DispatchFn = (state: MPIDOptionsState, actions: MPIDOptionsAction | MPIDOptionsAction[]) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const DispatchFnSwitch = (state: MPIDOptionsState, action: MPIDOptionsAction): MPIDOptionsState => {
  switch (action.type) {
    case "SET_USER_MPID_OPTIONS": {
      const { trMpidOptions, stMpidOptions } = action.payload;
      return { ...state, userMPIDOptions: { isReady: true, trMpidOptions, stMpidOptions } };
    }
    case "SET_CLEAR_CORR_MPID_OPTIONS": {
      const { trClearCorrOptions, activeRiskMpidOptions, activeTransitionalRiskMpidOptions } =
        action.payload;
      return {
        ...state,
        clearCorrMPIDOptions: {
          isReady: true,
          trClearCorrOptions,
          activeRiskMpidOptions,
          activeTransitionalRiskMpidOptions,
        },
      };
    }
    default:
      return { ...state };
  }
};

interface MPIDOptionsContextProps {
  children: React.ReactNode;
  defaultData?: MPIDOptionsState;
}

const addRelationshipToMap = (
  key: string,
  relationship: ClearingRelationship,
  map: { [mpid: string]: ClearingRelationship[] }
) => {
  if (Array.isArray(map[key])) {
    map[key].push(relationship);
  } else {
    map[key] = [relationship];
  }
};

const labelSort = (a: SelectOption, b: SelectOption) => {
  if (a.label > b.label) {
    return 1;
  } else if (a.label < b.label) {
    return -1;
  } else {
    return 0;
  }
};

const createOptionFromMPID = (mpid: string) => {
  return {
    id: mpid,
    label: mpid,
    value: mpid,
  };
};

const getOptionsForMPIDs = (mpids: string[]): SelectOption[] => {
  return mpids.map(mpid => createOptionFromMPID(mpid));
};

interface ClearingRelationship {
  id: number;
  clearingMPID: string;
  clearingNum: string;
  correspondentMPID: string;
  firstValidDay: string;
  riskStatus: string;
}

const getClearAndCorrOptions = (
  mpids: string[],
  relationshipMap: { [mpid: string]: ClearingRelationship[] },
  entitlement: string | null
): SelectOption[] => {
  switch (entitlement) {
    case KeycloakRoles.CLEARER:
      const knownClearers = new Set<string>();
      const knownCorrespondents = new Set<string>();
      mpids.forEach(mpid => {
        if (!knownClearers.has(mpid)) {
          knownClearers.add(mpid);
          knownCorrespondents.delete(mpid);
          if (Array.isArray(relationshipMap[mpid])) {
            relationshipMap[mpid].forEach(relationship => {
              if (!knownClearers.has(relationship.correspondentMPID)) {
                knownCorrespondents.add(relationship.correspondentMPID);
              }
            });
          }
        }
      });
      return [
        ...getOptionsForMPIDs(Array.from(knownClearers).sort()),
        ...getOptionsForMPIDs(Array.from(knownCorrespondents).sort()),
      ];
    case KeycloakRoles.CORRESPONDENT:
    default:
      return getOptionsForMPIDs(mpids);
  }
};

const getClearAndCorrParentChildOptions = (
  mpids: Set<string>,
  relationshipMap: { [mpid: string]: ClearingRelationship[] },
  entitlement: string | null
): SelectOption[] => {
  const options: SelectOption[] = [];
  switch (entitlement) {
    case KeycloakRoles.CLEARER: {
      Object.keys(relationshipMap).forEach(clearer => {
        if (mpids.has(clearer) && Array.isArray(relationshipMap[clearer])) {
          const children = relationshipMap[clearer]
            .map(relationship => {
              return {
                id: `${clearer}-${relationship.correspondentMPID}`,
                label: relationship.correspondentMPID,
                value: JSON.stringify(relationship),
              };
            })
            .sort(labelSort);
          options.push({
            id: clearer,
            label: `${clearer} (Clearing)`,
            value: clearer,
            children,
          });
        }
      });
      break;
    }
    case KeycloakRoles.CORRESPONDENT: {
      Object.keys(relationshipMap).forEach(clearer => {
        if (Array.isArray(relationshipMap[clearer])) {
          const children = relationshipMap[clearer]
            .reduce((acc, curr) => {
              if (mpids.has(curr.correspondentMPID)) {
                acc.push({
                  id: `${clearer}-${curr.correspondentMPID}`,
                  label: curr.correspondentMPID,
                  value: JSON.stringify(curr),
                });
              }
              return acc;
            }, [] as SelectOption[])
            .sort(labelSort);
          if (children.length > 0) {
            options.push({
              id: clearer,
              label: `${clearer} (Clearing)`,
              value: clearer,
              children,
            });
          }
        }
      });
      break;
    }
    default:
      console.warn(
        `User is not entitled as ${KeycloakRoles.CLEARER} or ${KeycloakRoles.CORRESPONDENT}`
      );
      break;
  }
  return options;
};

const setOptionsForForms = (options: SelectOption[], forms: { key: string }[]) => {
  forms.forEach(({ key }) => {
    MPIDFormOptionMap[key] = options;
  });
};

export const MPIDOptionsProvider: React.FC<MPIDOptionsContextProps> = ({
  children,
  defaultData,
}) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));
  const [user] = useUserContext();
  const fieldOptionDispatch = useFieldOptionDispatch();
  const formDispatch = useFormDispatch();
  const [isClearerCorrespondentReady, setClearerCorrespondentReady] = useState(false);

  /**
   * Maps risk active clearing MPID to its risk active correspondents
   */
  const clearerActiveMap = useRef<{ [mpid: string]: ClearingRelationship[] }>({});

  /**
   * Maps risk active clearing MPID to its risk active and transitional correspondents
   */
  const clearerActiveTransitionalMap = useRef<{ [mpid: string]: ClearingRelationship[] }>({});

  /**
   * Maps a clearing MPID to its correspondents
   */
  const clearerMap = useRef<{ [mpid: string]: ClearingRelationship[] }>({});

  /**
   * Maps a correspondent MPID to its clearers
   */
  const correspondentMap = useRef<{ [mpid: string]: ClearingRelationship[] }>({});

  /**
   * Set clearer/correspondent map data and single select options
   */
  useEffect(() => {
    if (user.clearingDataResult === RequestResult.success && Array.isArray(user.clearingData)) {
      let clearingOptions: SelectOption[] = [];
      const knownClearers = new Set();

      const addClearingOption = (relationship: ClearingRelationship) => {
        if (
          !knownClearers.has(relationship.clearingMPID) &&
          Array.isArray(user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_PTR_MPIDS]) &&
          user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_PTR_MPIDS].includes(
            relationship.clearingMPID
          )
        ) {
          knownClearers.add(relationship.clearingMPID);
          clearingOptions.push({
            id: relationship.id,
            label: relationship.clearingMPID,
            value: relationship.clearingMPID,
          });
        }
      };

      user.clearingData.forEach((relationship: ClearingRelationship) => {
        switch (relationship.riskStatus) {
          case RiskStatus.ACTIVE:
            addRelationshipToMap(relationship.clearingMPID, relationship, clearerActiveMap.current);
            addRelationshipToMap(
              relationship.clearingMPID,
              relationship,
              clearerActiveTransitionalMap.current
            );
            addRelationshipToMap(relationship.clearingMPID, relationship, clearerMap.current);
            addRelationshipToMap(
              relationship.correspondentMPID,
              relationship,
              correspondentMap.current
            );
            addClearingOption(relationship);
            break;
          case RiskStatus.TRANSITIONAL:
            addRelationshipToMap(
              relationship.clearingMPID,
              relationship,
              clearerActiveTransitionalMap.current
            );
            addRelationshipToMap(relationship.clearingMPID, relationship, clearerMap.current);
            addRelationshipToMap(
              relationship.correspondentMPID,
              relationship,
              correspondentMap.current
            );
            addClearingOption(relationship);
            break;
          case RiskStatus.INACTIVE:
            addRelationshipToMap(relationship.clearingMPID, relationship, clearerMap.current);
            break;
          default:
            break;
        }
      });
      setClearerCorrespondentReady(true);

      clearingOptions = clearingOptions.sort(labelSort);
      SelectOptions[FieldNames.clearingFirmMPID] = () => clearingOptions;
      SelectOptions[FieldNames.correspondentMPID] = (form: any, formData: any) => {
        const options: SelectOption[] = [];
        const clearer = formData?.fields[FieldNames.clearingFirmMPID]?.value;
        if (clearer && Array.isArray(clearerActiveTransitionalMap.current[clearer])) {
          clearerActiveTransitionalMap.current[clearer].forEach(relationship => {
            if ([RiskStatus.ACTIVE, RiskStatus.TRANSITIONAL].includes(relationship.riskStatus)) {
              options.push({
                id: relationship.correspondentMPID,
                label: relationship.correspondentMPID,
                value: relationship.correspondentMPID,
              });
            }
          });
        }
        return options.sort(labelSort);
      };

      SelectOptions[addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.participantMPID)] = async (
        form: Form,
        formData: any,
        userData: any,
        optionsCache: { [form in FORM_KEY]?: { [key: string]: SelectOption[] } }
      ) => {
        let options: SelectOption[] = [];
        if (formData) {
          const executingMPID = formData.fields[PTRA_AGU_ENTITY.executingMPID]?.value;

          if (executingMPID) {
            const optionCacheKey = `${PTRA_AGU_ENTITY.participantMPID}|${executingMPID}`;
            if (optionsCache[form.id]?.[optionCacheKey]) {
              return optionsCache[form.id]?.[optionCacheKey];
            }
            fieldOptionDispatch({
              type: "ADD_TO_LOADING_MAP",
              payload: {
                fieldName: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.participantMPID),
                form: form.id,
              },
            });
            const response = await fetch(
              formatUrl(process.env.REACT_APP_URL_QUERY_WS, "getAguClrCorrMPIDs"),
              {
                method: "POST",
                headers: getHeaders(),
                body: JSON.stringify([executingMPID]),
              }
            );
            if (response.ok) {
              const json: ClearingRelationship[] = await response.json();
              const clearerToOptions: { [clearer: string]: SelectOption } = {};
              json.forEach(relationship => {
                if (
                  [RiskStatus.ACTIVE, RiskStatus.TRANSITIONAL].includes(relationship.riskStatus)
                ) {
                  if (clearerToOptions[relationship.clearingMPID] === undefined) {
                    clearerToOptions[relationship.clearingMPID] = {
                      id: relationship.clearingMPID,
                      label: `${relationship.clearingMPID} (Clearing)`,
                      value: relationship.clearingMPID,
                      children: [],
                    };
                  }
                  (clearerToOptions[relationship.clearingMPID].children as SelectOption[]).push({
                    id: relationship.correspondentMPID,
                    label: relationship.correspondentMPID,
                    value: JSON.stringify(relationship),
                  });
                }
              });
              options = Object.values(clearerToOptions);
            } else {
              NotificationHub.send("danger", "Unable to retrieve MPID relationships");
            }
            fieldOptionDispatch([
              {
                type: "REMOVE_FROM_LOADING_MAP",
                payload: {
                  fieldName: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.participantMPID),
                  form: form.id,
                },
              },
              {
                type: "ADD_TO_OPTIONS_CACHE",
                payload: { form: form.id, key: optionCacheKey, options: options },
              },
            ]);
          }
        }
        return options;
      };
    }
  }, [
    fieldOptionDispatch,
    formDispatch,
    user.clearingData,
    user.clearingDataResult,
    user.mpidAttributes,
  ]);

  /**
   * Set SelectOptions for inputs that rely on clearer/correspondent relationships
   */
  useEffect(() => {
    if (user.mpidAttributes) {
      let clearerCorrespondentRole = null;
      if (user.entitlements[KeycloakRoles.CORRESPONDENT]) {
        clearerCorrespondentRole = KeycloakRoles.CORRESPONDENT;
      } else if (user.entitlements[KeycloakRoles.CLEARER]) {
        clearerCorrespondentRole = KeycloakRoles.CLEARER;
      }
      let trClearCorrOptions: SelectOption[] = [];
      if (Array.isArray(user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_TRADE_REPORTING_MPIDS])) {
        trClearCorrOptions = getClearAndCorrOptions(
          user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_TRADE_REPORTING_MPIDS],
          clearerMap.current,
          clearerCorrespondentRole
        );
        const trClearCorrForms = [
          Forms.TR_SCAN,
          Forms.TR_REJECTS,
          Forms.QUERY,
          Forms.RD_CLEARING,
          Forms.RD_AGU,
          Forms.RD_CUSIP,
        ];
        setOptionsForForms(trClearCorrOptions, trClearCorrForms);
      }

      if (
        Array.isArray(user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_REALTIME_STATISTICS_MPIDS])
      ) {
        const statsClearCorrOptions = getClearAndCorrOptions(
          user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_REALTIME_STATISTICS_MPIDS],
          isClearerCorrespondentReady ? clearerMap.current : {},
          clearerCorrespondentRole
        );
        const statsClearCorrForms = [Forms.ST_MIDDLE, Forms.RIGHT];
        setOptionsForForms(statsClearCorrOptions, statsClearCorrForms);
      }

      let ptrActiveParentChildOptions: SelectOption[] = [];
      let ptrActiveTransitionalParentChildOptions: SelectOption[] = [];
      if (Array.isArray(user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_PTR_MPIDS])) {
        ptrActiveParentChildOptions = getClearAndCorrParentChildOptions(
          new Set<string>(user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_PTR_MPIDS]),
          clearerActiveMap.current,
          clearerCorrespondentRole
        );
        const ptrActiveParentChildForms = [Forms.CV_TOP, Forms.RIGHT_LIMIT];
        setOptionsForForms(ptrActiveParentChildOptions, ptrActiveParentChildForms);

        ptrActiveTransitionalParentChildOptions = getClearAndCorrParentChildOptions(
          new Set<string>(user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_PTR_MPIDS]),
          clearerActiveTransitionalMap.current,
          clearerCorrespondentRole
        );
        const ptrActiveTransitionalParentChildForms = [
          Forms.SETTINGS_PTR_LIMIT_EXPORT,
          Forms.PTRA_CONFIG,
        ];
        setOptionsForForms(
          ptrActiveTransitionalParentChildOptions,
          ptrActiveTransitionalParentChildForms
        );
      }
      dispatchF({
        type: "SET_CLEAR_CORR_MPID_OPTIONS",
        payload: {
          trClearCorrOptions,
          activeRiskMpidOptions: ptrActiveParentChildOptions,
          activeTransitionalRiskMpidOptions: ptrActiveTransitionalParentChildOptions,
        },
      });
    }
  }, [isClearerCorrespondentReady, user.entitlements, user.mpidAttributes]);

  useEffect(() => {
    if (user.mpidAttributes) {
      let trOptions: SelectOption[] = [];
      if (Array.isArray(user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_TRADE_REPORTING_MPIDS])) {
        trOptions = getOptionsForMPIDs(
          user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_TRADE_REPORTING_MPIDS]
        );
        const trForms = [
          Forms.TR_REPORT,
          Forms.TR_MODIFY,
          Forms.TR_MATCH,
          Forms.TR_REPAIR,
          Forms.TR_COPY,
          Forms.UPLOAD_REPAIR,
          Forms.PTRA_AGU_CONFIG,
          Forms.PVR_CONFIG,
          Forms.PV_MONITOR_MEMBER_TOP,
        ];
        setOptionsForForms(trOptions, trForms);
      }

      let statsOptions: SelectOption[] = [];
      if (
        Array.isArray(user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_REALTIME_STATISTICS_MPIDS])
      ) {
        statsOptions = getOptionsForMPIDs(
          user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_REALTIME_STATISTICS_MPIDS]
        );
        const statsForms = [Forms.ST_COPY, Forms.ST_MATCH, Forms.ST_MODIFY, Forms.ST_REPAIR];
        setOptionsForForms(statsOptions, statsForms);
      }

      dispatchF({
        type: "SET_USER_MPID_OPTIONS",
        payload: {
          trMpidOptions: trOptions,
          stMpidOptions: statsOptions,
        },
      });
    }
  }, [user.mpidAttributes, user.clearingData, user.clearingDataResult, user.entitlements]);

  return (
    <userMPIDsDispatch.Provider value={dispatchF}>
      <userMPIDsContext.Provider value={[state, dispatchF]}>{children}</userMPIDsContext.Provider>
    </userMPIDsDispatch.Provider>
  );
};
