import { BarChart } from "@nef/charts";
import { All_Or_Pending, Mpid_Or_Security, PorOrPvr } from "../context";
import { TileBack } from "./tile.back";
import { HeaderBox } from "./tile.container";

type ChartData = {
  name: string;
  values: {
    name: string;
    value: number;
  }[];
}[];

type BackProps = {
  toggle: () => void;
  isAllOrPending: All_Or_Pending;
  isMpidOrSymbol: Mpid_Or_Security;
  mpidChartData: ChartData;
  symbolChartData: ChartData;
  totalRejectCount: number;
};

export const Back: React.FC<BackProps> = ({
  toggle,
  isAllOrPending,
  isMpidOrSymbol,
  mpidChartData,
  symbolChartData,
  totalRejectCount,
}) => {
  return (
    <TileBack
      HeaderBox={
        <HeaderBox
          toggle={toggle}
          hasAllOrPending={false}
          hasMpidOrSymbol={true}
          isAllOrPending={isAllOrPending}
          isMpidOrSymbol={isMpidOrSymbol}
          isPorOrPvr={PorOrPvr.PVR}
          total={totalRejectCount}
          title={"Price Rejects"}
        />
      }
      title1={`Top 5 By ${isMpidOrSymbol}`}
      chart={
        <BarChart
          data={isMpidOrSymbol === "MPID" ? mpidChartData : symbolChartData}
          // legend={true}
          // onClick={doTableChart}
        />
      }
    />
  );
};
