import { NotificationHub } from "@nef/core";
import { Forms } from "components/fields/fieldConstants";
import { useFormContext, useFormDispatch } from "components/form";
import {
  getSelectedRows,
  StandardHeader,
  StandardTable,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StandardTables } from "wksConstants";
import { SettingsWrapper } from "../settingsWrapper";
import { EQRC_RULE_STATUS, EqrcFields, getEqrcIdFromResponse } from "./constants";
import { DAY_TYPES } from "./constants2";
import { useEqrcRuleContext, useEqrcRuleDispatch } from "./context";
import { EQRCEditAlertForm } from "./alert/eqrcAlertEditForm";
import { urls } from "./network";
import { EQRCEditForm, EQRCNewForm } from "./rules";
import { setActiveAndConfiguredRules } from "./rules/helpers";
import { AlertTableButtons } from "./alert/tableButtons";
import { RulesTableButtons } from "./rules/tableButtons";
import { SettingsHeaderMenu } from "./components/settingsHeaderMenu";
import { Tab2 } from "./components/tab2";
import {
  generateCreateFormHeader,
  generateEditAlertFormHeader,
  generateNewAlertFormHeader,
  generateResultsHeaders,
} from "./transformers/generateHeaders";
import { EQRCNewAlertForm } from "./alert/eqrcNewAlertForm";
import { ResultsTable } from "./rules/results";
import { useActiveVars } from "./rules/useActiveVars";

export const EQRCAlertSettingsTable = ({ augmentOnChange }) => {
  const [tableData] = useStandardTableContext();
  const tableDispatch = useStandardTableDispatch();
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const eqrcRuleDispatch = useEqrcRuleDispatch();
  const [eqrcRuleData] = useEqrcRuleContext();

  const [isNewModalVisible, setNewModalVisible] = useState(false);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isNewAlertModalVisible, setNewAlertModalVisible] = useState(false);
  const [isEditAlertModalVisible, setEditAlertModalVisible] = useState(false);
  const [isResultsTableVisible, setResultsTableVisible] = useState(false);
  const [isAuditLoading, setAuditLoading] = useState(false);

  const [activeTable, setActiveTable] = useState(StandardTables.EQRC_FAT_FINGER);
  const [lastTableRefresh, setLastTableRefresh] = useState(null);
  const [lastTableState, setTableState] = useState({ isLoading: false, data: null });
  const [lastActiveOrConfigured, setLastActiveOrConfigured] = useState(EQRC_RULE_STATUS.Active);
  const [currentStep, setCurrentStep] = useState(0);

  const activeOrConfigured = useMemo(() => {
    return formData[Forms.EQRC_ACTIVE_OR_CONFIGURED_TABLE.key].fields[EqrcFields.status];
  }, [formData]);

  const getEditStatus = useCallback(() => {
    return formData[Forms.EQRC_ACTIVE_OR_CONFIGURED_TABLE.key].fields[EqrcFields.status];
  }, [formData]);

  const {
    activeCreateFieldSets,
    activeEditFieldSets,
    activeNewForm,
    activeEditForm,
    activeRuleType,
    totalSteps,
  } = useActiveVars({ activeTable, currentStep });

  const refreshTableData = useCallback(
    table => {
      const requestMap = {
        [urls[table]]: new Set([EQRC_RULE_STATUS.Active, EQRC_RULE_STATUS.Configured]),
      };
      eqrcRuleDispatch({
        type: "SET_REQUESTING",
        payload: { isRequesting: true, requestMap },
      });
    },
    [eqrcRuleDispatch]
  );

  const refreshCurrentTableData = useCallback(() => {
    refreshTableData(activeTable);
  }, [activeTable, refreshTableData]);

  const handleCloseNew = useCallback(() => {
    formDispatch({ type: "RESET_FORM", payload: { form: activeNewForm } });
    setNewModalVisible(false);
    setCurrentStep(0);
  }, [activeNewForm, formDispatch]);

  const handleCloseEdit = useCallback(() => {
    formDispatch({ type: "RESET_FORM", payload: { form: activeEditForm } });
    setEditModalVisible(false);
    setCurrentStep(0);
  }, [activeEditForm, formDispatch]);

  const handleCloseNewAlert = useCallback(() => {
    formDispatch({ type: "RESET_FORM", payload: { form: Forms.EQRC_ALERT_CONFIG } });
    setNewAlertModalVisible(false);
  }, [formDispatch]);

  const handleCloseEditAlert = useCallback(() => {
    formDispatch({ type: "RESET_FORM", payload: { form: Forms.EQRC_EDIT_ALERT } });
    setEditAlertModalVisible(false);
  }, [formDispatch]);

  const handleCloseResults = useCallback(() => {
    tableDispatch({ type: "SET_DATA_EMPTY", payload: { table: `${activeTable}Results` } });
    setResultsTableVisible(false);
  }, [activeTable, tableDispatch]);

  useEffect(() => {
    formDispatch({
      type: "INIT_FORM_VALIDATION",
      payload: { form: Forms.EQRC_AUDIT_MENU },
    });
  }, [formDispatch]);

  const titleMap = useMemo(
    () => ({
      [StandardTables.EQRC_FAT_FINGER]: "Fat Finger Rules",
      [StandardTables.EQRC_RESTRICTED_STOCK_LIST]: "Restricted Stock List Rules",
      [StandardTables.EQRC_GROSS_EXPOSURE]: "Gross Exposure Rules",
      [StandardTables.EQRC_MARKET_IMPACT_CHECK]: "Market Impact Rules",
      [StandardTables.EQRC_ORDER_TYPE]: "Order Type Rules",
      [StandardTables.EQRC_ADV_CHECK]: "Average Daily Volume Rules",
      [StandardTables.EQRC_ORDER_RATE_THRESHOLDS]: "Order Rate Threshold Rules",
      [StandardTables.EQRC_SHORT_SALE]: "Short Sale Rules",
      [StandardTables.EQRC_ALERT_CONFIG]: "EQRC Alert Configuration",
      [StandardTables.EQRC_MAX_NOTIONAL_ORDER]: "Max Notional Order",
      [StandardTables.EQRC_MAX_SHARES_PER_ORDER]: "Max Shares Per Order",
      [StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST]: "Shares Located / Broker List",
    }),
    []
  );

  useEffect(() => {
    let setLastTableState = false;
    const newTableState = { ...lastTableState };
    const tableActions = [];
    if (
      lastActiveOrConfigured !== activeOrConfigured ||
      lastTableState.data !== eqrcRuleData[urls[activeTable]]
    ) {
      const active = eqrcRuleData[urls[activeTable]][EQRC_RULE_STATUS.Active];
      const configured = eqrcRuleData[urls[activeTable]][EQRC_RULE_STATUS.Configured];

      if (active && configured) {
        const { rules, inactiveRules } = setActiveAndConfiguredRules(
          active,
          configured,
          activeOrConfigured
        );

        switch (activeOrConfigured) {
          case EQRC_RULE_STATUS.Active: {
            Object.entries(rules).forEach(([ruleKey, data]) => {
              switch (activeTable) {
                case StandardTables.EQRC_MAX_NOTIONAL_ORDER:
                case StandardTables.EQRC_MAX_SHARES_PER_ORDER: {
                  if (!inactiveRules[ruleKey]) {
                    switch (activeTable) {
                      case StandardTables.EQRC_MAX_NOTIONAL_ORDER: {
                        if (data[EQRC_RULE_STATUS.Active][EqrcFields.perOrderNotionalLimit] === 0) {
                          delete rules[ruleKey];
                        }
                        break;
                      }
                      case StandardTables.EQRC_MAX_SHARES_PER_ORDER: {
                        if (data[EQRC_RULE_STATUS.Active][EqrcFields.perOrderSharesLimit] === 0) {
                          delete rules[ruleKey];
                        }
                        break;
                      }
                      default:
                        break;
                    }
                  }
                  break;
                }
                case StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST: {
                  if (data[EQRC_RULE_STATUS.Active][EqrcFields.hasSharesLocateRule] === false) {
                    delete rules[ruleKey];
                  }
                  break;
                }
                default:
                  break;
              }

              if (!inactiveRules[ruleKey] && rules[ruleKey]) {
                rules[ruleKey].disabled = true;
              }
            });

            break;
          }
          case EQRC_RULE_STATUS.Configured: {
            Object.entries(rules).forEach(([ruleKey, data]) => {
              if (inactiveRules[ruleKey]) {
                rules[ruleKey].showActiveConfiguredWarning = true;
              }

              if (activeTable === StandardTables.EQRC_SHARES_LOCATED_CHECK) {
                if (data[EQRC_RULE_STATUS.Configured][EqrcFields.hasSharesLocateRule] === false) {
                  delete rules[ruleKey];
                }
              }
            });
            break;
          }
          default:
            break;
        }

        let shouldSwapActiveConfigured = false;
        if (
          activeTable === StandardTables.EQRC_ALERT_CONFIG &&
          activeOrConfigured === EQRC_RULE_STATUS.Configured
        ) {
          shouldSwapActiveConfigured = true;
        }

        const inverseActiveOrConfigured =
          activeOrConfigured === EQRC_RULE_STATUS.Active
            ? EQRC_RULE_STATUS.Configured
            : EQRC_RULE_STATUS.Active;

        // make sure active rules have access to their configured rule
        Object.entries(rules).forEach(([ruleKey, data]) => {
          if (inactiveRules[ruleKey]) {
            data[inverseActiveOrConfigured] = inactiveRules[ruleKey][inverseActiveOrConfigured];
          }
          data.status = activeOrConfigured;
        });

        const rulesToUse = shouldSwapActiveConfigured ? inactiveRules : rules;
        tableActions.push({
          type: "SET_TABLE_DATA",
          payload: {
            table: activeTable,
            data: Object.values(rulesToUse),
          },
        });
      } else {
        NotificationHub.send("danger", "Error parsing rules data");
      }

      setLastTableState = true;
      newTableState.data = eqrcRuleData[urls[activeTable]];
    }

    if (lastTableState.isLoading === false && eqrcRuleData.loadingSet.has(urls[activeTable])) {
      tableActions.push({
        type: "SET_LOADING",
        payload: { table: activeTable },
      });

      setLastTableState = true;
      newTableState.isLoading = true;
    } else if (
      lastTableState.isLoading === true &&
      !eqrcRuleData.loadingSet.has(urls[activeTable])
    ) {
      tableActions.push({
        type: "SET_NOT_LOADING",
        payload: { table: activeTable },
      });

      setLastTableState = true;
      newTableState.isLoading = false;
    }

    if (setLastTableState) {
      tableDispatch(tableActions);
      setTableState(newTableState);
      setLastActiveOrConfigured(activeOrConfigured);
    }
  }, [
    eqrcRuleData,
    activeTable,
    tableDispatch,
    lastTableState,
    lastActiveOrConfigured,
    activeOrConfigured,
  ]);

  useEffect(() => {
    if (activeTable !== lastTableRefresh || activeOrConfigured !== lastActiveOrConfigured) {
      refreshTableData(activeTable);
      setLastTableRefresh(activeTable);
      setLastActiveOrConfigured(activeOrConfigured);
      tableDispatch({ type: "CLEAR_SELECTED", payload: { table: activeTable } });
    }
  }, [
    activeOrConfigured,
    activeTable,
    lastActiveOrConfigured,
    lastTableRefresh,
    refreshTableData,
    tableDispatch,
  ]);

  const handleTabClick = useCallback(
    table => () => {
      setActiveTable(table);
      refreshTableData(table);
      setCurrentStep(0);
    },
    [refreshTableData]
  );

  const tableButtons = useMemo(() => {
    if (activeTable === StandardTables.EQRC_ALERT_CONFIG) {
      return (
        <AlertTableButtons
          setNewAlertModalVisible={setNewAlertModalVisible}
          setEditAlertModalVisible={setEditAlertModalVisible}
          activeTable={activeTable}
          editStatus={getEditStatus()}
          activeEditForm={activeEditForm}
        />
      );
    } else {
      const selected = getSelectedRows(tableData[activeTable]);

      return (
        <RulesTableButtons
          table={activeTable}
          activeEditForm={activeEditForm}
          activeNewForm={activeNewForm}
          status={getEditStatus()}
          setNewModalVisible={setNewModalVisible}
          setEditModalVisible={setEditModalVisible}
          setNewAlertModalVisible={setNewAlertModalVisible}
          setAuditLoading={setAuditLoading}
          onClickRefresh={refreshCurrentTableData}
          refreshCurrentTableData={refreshCurrentTableData}
          selected={selected}
          isAuditLoading={isAuditLoading}
          activeOrConfigured={activeOrConfigured}
          disableEdit={selected?.[0]?.disabled}
          showActiveConfiguredWarning={selected?.[0]?.showActiveConfiguredWarning}
        />
      );
    }
  }, [
    activeTable,
    getEditStatus,
    activeEditForm,
    tableData,
    activeNewForm,
    refreshCurrentTableData,
    isAuditLoading,
    activeOrConfigured,
  ]);

  const Header = useMemo(() => {
    const todayOrTomorrow = DAY_TYPES[activeOrConfigured];
    const isAlertTable = activeTable === StandardTables.EQRC_ALERT_CONFIG;

    let text = `${titleMap[activeTable]}`;
    if (!isAlertTable) {
      text += ` for ${todayOrTomorrow}`;
    }

    return () => <StandardHeader title={text} />;
  }, [activeOrConfigured, activeTable, titleMap]);
  const showResults = ({ data, show } = { data: [], show: true }) => {
    const dataToSend = data.map(row => {
      row.eqrcId = getEqrcIdFromResponse(row[row.status]);

      return {
        data: row,
        idField: "eqrcId",
        idValue: getEqrcIdFromResponse(row[row.status]),
        mergeFn: (newRow, oldRow) => {
          if (oldRow[row.status][EqrcFields.sharesLocatedOption]) {
            oldRow[row.status][EqrcFields.sharesLocateRule] = {
              [EqrcFields.sharesLocatedOption]: oldRow[row.status][EqrcFields.sharesLocatedOption],
            };
          }

          return {
            ...oldRow,
            eqrcId: getEqrcIdFromResponse(row[row.status]),
            [row.status]: {
              ...oldRow[row.status],
              requestStatus: {
                ...newRow[row.status].requestStatus,
              },
            },
          };
        },
      };
    });

    if (show !== false) {
      setResultsTableVisible(true);
    }
    tableDispatch({
      type: "UPDATE_OR_ADD_TABLE_DATA",
      payload: {
        table: `${activeTable}Results`,
        data: dataToSend,
      },
    });
  };

  return (
    <>
      <SettingsWrapper
        header={"EQRC Rules"}
        headerMenu={<SettingsHeaderMenu activeTable={activeTable} />}
        errorMessage={formData[Forms.SETTINGS_TR.key].globalErrorMessage}
        hasSave={false}
        disableFormWrapperClass={true}
        tabs={<Tab2 handleTabClick={handleTabClick} activeTable={activeTable} />}
      >
        <StandardTable
          header={Header}
          table={activeTable}
          enableLayoutExport={true}
          id={"eqrc-tables"}
          isSingleSelect={false}
          isColumnsVirtualized={false}
          isFilterable={true}
          headerMenu={tableButtons}
          hideRowCount={false}
          hideSelectedCount={false}
        />
      </SettingsWrapper>
      <EQRCNewForm
        table={activeTable}
        header={generateCreateFormHeader(activeTable, activeRuleType, totalSteps, currentStep)}
        form={activeNewForm}
        fieldSets={activeCreateFieldSets}
        isOpen={isNewModalVisible}
        onClose={handleCloseNew}
        setStep={setCurrentStep}
        currentStep={currentStep}
        totalSteps={totalSteps}
        showResults={showResults}
      />
      {activeTable !== StandardTables.EQRC_ALERT_CONFIG ? (
        <EQRCEditForm
          table={activeTable}
          form={activeEditForm}
          fieldSets={activeEditFieldSets}
          isOpen={isEditModalVisible}
          onClose={handleCloseEdit}
          setStep={setCurrentStep}
          currentStep={currentStep}
          totalSteps={totalSteps}
          ruleType={activeRuleType}
          showResults={showResults}
          refreshCurrentTableData={refreshCurrentTableData}
        />
      ) : (
        <></>
      )}
      <EQRCNewAlertForm
        table={activeTable}
        header={generateNewAlertFormHeader(activeTable, activeRuleType)}
        isOpen={isNewAlertModalVisible}
        onClose={handleCloseNewAlert}
        ruleType={activeRuleType}
        form={
          activeTable !== StandardTables.EQRC_ALERT_CONFIG
            ? Forms.EQRC_ALERT_CONFIG
            : Forms.EQRC_NEW_ALERT
        }
        fieldSets={activeCreateFieldSets}
      />
      <EQRCEditAlertForm
        table={activeTable}
        header={generateEditAlertFormHeader(activeTable, tableData)}
        isOpen={isEditAlertModalVisible}
        onClose={handleCloseEditAlert}
        ruleType={activeRuleType}
        form={activeEditForm}
        fieldSets={activeEditFieldSets}
      />
      {activeTable !== StandardTables.EQRC_ALERT_CONFIG ? (
        <ResultsTable
          table={activeTable}
          isOpen={isResultsTableVisible}
          onClose={handleCloseResults}
          activeTable={activeTable}
          header={generateResultsHeaders(activeRuleType)}
        />
      ) : (
        <></>
      )}
    </>
  );
};
