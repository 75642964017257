import { NotificationHub } from "@nef/core";
import { SubtitleList } from "components/notification";

export enum Status {
  NO_STATUS = "primary",
  ERROR = "danger",
  SUCCESS = "success",
  WARN = "warning",
}

export enum WorkXResponseDTO {
  BulkResubmitResponseDTO = "BulkResubmitResponseDTO",
}

export type WorkXResponse<BodyT = any> = {
  body?: BodyT;
  bodyName?: string;
  errorCode?: string;
  errorMessages?: string[];
};

export enum NotificationColor {
  ERROR = "danger",
  SUCCESS = "success",
  WARN = "warning",
  PRIMARY = "primary",
}

export const sendWorkXResponseNotification = ({
  response,
  color,
  title,
}: {
  response: WorkXResponse;
  color: NotificationColor;
  title: string;
}) => {
  NotificationHub.send(color, title, {
    subtitle:
      response.errorMessages !== undefined && response.errorMessages.length > 0 ? (
        <SubtitleList messages={response.errorMessages} />
      ) : undefined,
  });
};
