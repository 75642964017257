import { getColor } from "@nef/style-utils";
import styled from "styled-components";

export const ArrayButton = styled.button`
  padding: 0;
  border: none;
  color: ${getColor("primary", 500)};
  background: none;
  cursor: pointer;
  font-size: 12px;
`;
