import { CircleButton, FontAwesomeIcon, Header, Indicator } from "@nef/core";
import { getColor, getShadow } from "@nef/style-utils";
import { LimoLVCIndicator } from "components/limitMonitor";
import React from "react";
import styled from "styled-components";
import { abbreviateNumber } from "utils/js.utils";
import { Status } from "wksConstantsTS";

const borderRadius = "5px";
const CHART_HEIGHT_PX = 300;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  height: 100%;
  grid-template-rows: 30px ${CHART_HEIGHT_PX}px calc(100% - ${CHART_HEIGHT_PX}px - 30px - 2rem);
`;

const ServiceIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
`;

export const ArraySection = styled.section`
  ${props => `
    background-color: ${getColor("gray", 0)(props)};
    height: 100%;
    overflow: hidden;
    border: 1px solid black;
    box-shadow: ${getShadow(1)};
    border: 1px solid rgb(230, 230, 230);
    border-radius: ${borderRadius};
    padding: 1rem;
    box-sizing: border-box;
    grid-column: span 1;
  `}
`;

export const ArrayWrapper = styled.div`
  padding-right: 1rem;
  box-sizing: border-box;
  overflow-y: scroll;
  height: calc(100% - 65px - 1rem);
`;

export const FlipCardHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type RejectsFlipCardSectionProps = {
  totalRejectCount: number;
  toggleFlipped: () => void;
  children: React.ReactNode | JSX.Element;
  cacheStatus: Status;
};

export const RejectsFlipCardSection: React.FC<RejectsFlipCardSectionProps> = ({
  totalRejectCount,
  toggleFlipped,
  children,
  cacheStatus,
}) => {
  return (
    <>
      <FlipCardHeader size={3}>
        <ServiceIndicatorWrapper>
          Price Rejects By Symbol - {abbreviateNumber(totalRejectCount)}
          <LimoLVCIndicator
            id={"rej-symbol-count-indicator"}
            status={cacheStatus}
            prefix={undefined}
          />
        </ServiceIndicatorWrapper>
        <CircleButton onClick={toggleFlipped} ghost={true} size="xs">
          <FontAwesomeIcon iconClassName="fa-chart-bar" />
        </CircleButton>
      </FlipCardHeader>
      {children}
    </>
  );
};
