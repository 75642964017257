import {
  getSelectedRows,
  StandardHeader,
  StandardTable,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import styled from "styled-components";
import { StandardTables, TableButtonAction, TableButtonLabel } from "wksConstants";
import { useCallback, useMemo } from "react";
import TableButtons from "components/standardTable/tableButtons";
import { PorWindowTimer } from "components/pvRejects/porWindowTimer";
import { getHeaders } from "keycloak";
import { formatUrl } from "utils/js.utils";
import { doFetchWrapper } from "network";
import { NotificationHub } from "@nef/core";
import { ViewActions } from "viewConstants";
import { useUserContext } from "components/user";
import { WorkXResponse } from "wksConstantsTS";
import {
  getSaveAllErrorSubtitle,
  PorProcessManyDto,
  PorProcessManyStatus,
} from "components/pvRejects/member/constant";

const LeftTableWrapper = styled.div`
  grid-column: span 1;
`;

const StatusChangeLabel = {
  [TableButtonAction.PVR_APPROVE]: "Approved",
  [TableButtonAction.PVR_DENY]: "Denied",
};

const changeStatusSuccessMessage = (
  newStatus: string,
  response: WorkXResponse<PorProcessManyDto>
) => `Status has been updated to ${newStatus} for ${response.body?.successful?.length} POR(s).`;

const changeStatusFailedMessage = (newStatus: string, response: WorkXResponse<PorProcessManyDto>) =>
  `Failed to update status to ${newStatus} for ${response.body?.failed?.length} POR(s).`;

export const PorTable = ({ toggle, title }: { toggle?: () => void | undefined; title: string }) => {
  const [tableData] = useStandardTableContext(); // TODO - this shouldn't be here
  const tableDispatch = useStandardTableDispatch();
  const [userData] = useUserContext();

  const TheHeader = useCallback(() => {
    return <StandardHeader title={title} />;
  }, [title]);

  const TheOtherHeader = useCallback(() => {
    return <PorWindowTimer marginTopPx={7} />;
  }, []);

  const handlePorAction = useCallback(
    (status: string) => () => {
      const rows = getSelectedRows(tableData[StandardTables.PV_SUPERVISOR_MONITOR2]);
      const body = rows.map(row => ({ id: row.id, statusChangeEvent: status }));

      const actionPorSuccess = (response: WorkXResponse<PorProcessManyDto>) => {
        tableDispatch({
          type: "DESELECT_ALL_ROWS",
          payload: { table: StandardTables.PV_SUPERVISOR_MONITOR2 },
        });
        switch (response.body?.status) {
          case PorProcessManyStatus.SUCCESS:
            NotificationHub.send(
              "success",
              changeStatusSuccessMessage(StatusChangeLabel[status], response)
            );
            break;
          case PorProcessManyStatus.PARTIAL:
            NotificationHub.send(
              "warning",
              `${changeStatusSuccessMessage(
                StatusChangeLabel[status],
                response
              )} ${changeStatusFailedMessage(StatusChangeLabel[status], response)}`,
              { subtitle: getSaveAllErrorSubtitle(response.errorMessages) }
            );
            break;
          case PorProcessManyStatus.FAILED:
            NotificationHub.send(
              "danger",
              changeStatusFailedMessage(StatusChangeLabel[status], response),
              {
                subtitle: getSaveAllErrorSubtitle(response.errorMessages),
              }
            );
            break;
          default:
            break;
        }
      };

      const actionPorError = () => {
        NotificationHub.send("danger", `Error updating POR status to ${status}`);
      };
      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_PVR_POR_SERVICE, "por/changeStatuses"),
        {
          method: "put",
          headers: getHeaders(),
          body: JSON.stringify(body),
        },
        actionPorSuccess,
        actionPorError
      );
    },
    [tableData, tableDispatch]
  );

  const disabled = true;
  const tableButtons = useMemo(() => {
    if (userData.allowed.actions[ViewActions.FINRA_PRO]) {
      const buttons = [
        {
          icon: "check",
          text: TableButtonLabel.APPROVE,
          actionId: TableButtonAction.PVR_APPROVE,
          requireSelect: true,
          allowMultiSelect: true,
          onClick: handlePorAction(TableButtonAction.PVR_APPROVE),
          allowConfirm: true,
          canConfirm: true,
          allowMenuOverride: false,
          disable: disabled,
        },
        {
          icon: "times",
          text: TableButtonLabel.DENY,
          actionId: TableButtonAction.PVR_DENY,
          requireSelect: true,
          allowMultiSelect: true,
          onClick: handlePorAction(TableButtonAction.PVR_DENY),
          allowConfirm: true,
          canConfirm: true,
          allowMenuOverride: false,
          disable: disabled,
        },
      ];
      return <TableButtons table={StandardTables.PV_SUPERVISOR_MONITOR2} buttons={buttons} />;
    }
    return undefined;
  }, [disabled, handlePorAction, userData.allowed.actions]);

  return (
    <LeftTableWrapper>
      <StandardTable
        header={TheHeader}
        table={StandardTables.PV_SUPERVISOR_MONITOR2}
        enableLayoutExport={true}
        isSingleSelect={false}
        isColumnsVirtualized={false}
        isFilterable={true}
        subHeader={undefined}
        hideRowCount={false}
        hideSelectedCount={false}
        hideQueryDate={true}
        headerMenu={tableButtons}
        exportCallback={undefined}
        exportFileName={undefined}
        additionalRowClick={undefined}
        isEditableTableEnabled={undefined}
        style={undefined}
        isRowSelectEnabled={undefined}
        Header={TheOtherHeader}
      />
    </LeftTableWrapper>
  );
};
