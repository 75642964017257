import { BarChart } from "@nef/charts";
import { DataArray, FormField, Header, Loader, NotificationHub, Section } from "@nef/core";
import { getColor } from "@nef/style-utils";
import { PageLoader } from "components/content/pageLoader";
import { ApiResponseNames, FieldNames, Forms, REJECT_DATE_OPTIONS } from "components/fields";
import { FlipCard } from "components/flipCard";
import { useFormContext, useFormDispatch } from "components/form";
import { LimoLVCIndicator } from "components/limitMonitor/serviceIndicator";
import { Middle } from "components/middle";
import {
  PorServiceErrorResponse,
  PV_REJECT_LABELS,
  PV_REJECT_MESSAGE,
  REJ_PRICE_OO_OVERRIDE_RANGE,
  REJ_PRICE_OO_RANGE,
} from "components/pvRejects/constant";
import { CloseButton, RecapButton } from "components/standardTable/buttons";
import { StandardHeader } from "components/standardTable/standardHeader";
import { StandardTable } from "components/standardTable/standardTable";
import {
  getSelectedRows,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable/standardTableContext";
import TableButtons from "components/standardTable/tableButtons";
import { useBulkJobDispatch } from "components/topBar/bulkJob/context";
import { TradeDetailRecap, useRecapContext } from "components/tradeDetailRecap";
import { useUserContext } from "components/user";
import { useMPIDOptionsContext } from "components/user/mpidContext";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { EffectCallback, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { SelectOption } from "types";
import { formatUrl } from "utils/js.utils";
import { ViewActions } from "viewConstants";
import { StandardTables, TableButtonAction, TableButtonLabel } from "wksConstants";
import {
  NotificationColor,
  sendWorkXResponseNotification,
  WorkXResponse,
  WorkXResponseDTO,
} from "wksConstantsTS";
import { useLayoutContext } from "../../context";
import { filterForDataArray } from "../constant";
import { POR_CACHE_FILTER_BY, usePorCacheDispatch, usePorCacheState } from "../porCache";
import { PorWindowTimer } from "../porWindowTimer";
import { ArrayButton } from "../styled";
import {
  DATE_FILTER,
  getSaveAllErrorSubtitle,
  getSaveAllFailedMessage,
  getSaveAllSuccessMessage,
  PORModel,
  PorProcessManyDto,
  PorProcessManyStatus,
  PV_REJECT_TOP,
  PvReject,
  PvRejectModel,
} from "./constant";
import { PorButtonHeader } from "./porButtonHeader";
import { REJECT_CACHE_FILTER_BY, useRejectCacheDispatch, useRejectCacheState } from "./rejectCache";
import {
  REJECT_COUNT_FILTER_BY,
  useRejectCountDispatch,
  useRejectCountState,
} from "./rejectCountCache";
import { ProMonitorRejectPieChart } from "./rejectPieChart";
import { RejectsFlipCardSection } from "./rejectsFlipCardSection";
import { PorClientMonitorInputs } from "./topInputs";

const CHART_HEIGHT_PX = 300;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  height: 100%;
  grid-template-rows: 7px calc(23px - 1rem) ${CHART_HEIGHT_PX}px calc(
      100% - ${CHART_HEIGHT_PX}px - 30px - 2rem
    );
`;

const ServiceIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
`;

export const ArraySection = styled(Section)`
  ${props => `
    background-color: ${getColor("gray", 0)(props)};
    height: 100%;
    overflow: hidden;
    padding: 1rem !important;
    box-sizing: border-box;
    grid-column: span 1;
  `}
`;

export const ArrayWrapper = styled.div`
  padding-right: 1rem;
  box-sizing: border-box;
  overflow-y: scroll;
  height: calc(100% - 65px - 1rem);
`;

const ChartSection = styled(Section)`
  ${props => `
    background-color: ${getColor("gray", 0)(props)};
    padding: 1rem !important;
    box-sizing: border-box;
        grid-column: span 1;
  `}
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  height: 100%;
`;

const LoaderWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 0.5rem;
`;

const PieChartWrapper = styled.div`
  aspect-ratio: 1 / 1;
  width: ${CHART_HEIGHT_PX - 75}px;
`;

const TableWrapper = styled.div`
  grid-column: span 4;
`;

const RequestTableWrapper = styled.div`
  grid-column: 3 / 5;
  grid-row: 2 / 4;
`;

const FlipChartContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const FlipCardHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoRejectsFiller = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FieldWrapper = styled.div`
  grid-column: span 2;
  grid-row: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
`;

const TimerWrapper = styled.div`
  grid-column: 3 / 5;
  grid-row: 1 / 2;
`;

enum ResubmitMessageSuffix {
  PRICE_OVERRIDE = "Price Override",
  PRICE_VALIDATION_OFF = "Price Validation Off",
}
const getResubmitSuccessMessage = (numProcessing: number, messageSuffix: string) => {
  return `Processing ${numProcessing} reject(s) with ${messageSuffix}.`;
};

const getResubmitFailedMessage = (numProcessing: number, messageSuffix: string) => {
  return `${numProcessing} reject(s) failed to process with ${messageSuffix}.`;
};

const getResubmitErrorMessage = (messageSuffix: string) => {
  return `Error resubmitting rejects with ${ResubmitMessageSuffix.PRICE_VALIDATION_OFF}`;
};

const NoRejectsFillerJsx = <NoRejectsFiller>No Rejects Found</NoRejectsFiller>;

type DataArrayCount = {
  label: string;
  value: number;
  subRows: { label: string; value: JSX.Element; count: number }[];
};

type PieChartData = { name: string; value: number }[];

enum BulkResubmitStatus {
  Success = "Success", // Request was good
  Reject = "Reject", // i.e. ACT Reject
  Failed = "Failed", // i.e. Bad Request
  Error = "Error", // i.e. Runtime exception, 500s
  Ignore = "Ignore",
}

type BulkResubmitResponseDTO = {
  status: BulkResubmitStatus;
  numProcessing: number;
  numWorkxRejects: number;
  data: BulkJob;
};

type BulkJob = {
  countAccepted: number;
  countCsvRows: number;
  countFailed: number;
  countRejected: number;
  countUnknown: number;
  data: string;
  fileHash: string;
  fileName: string;
  fileType: string;
  id: string;
  inputSource: string;
  jobCompletedTime: string;
  jobReceivedTime: string;
  lastUpdateTime: string;
  numItems: number;
  status: string;
  uploadStartedTime: string;
  userName: string;
};

const ALL_VAL = "*";

const emptyMpids: SelectOption[] = [];
export const PvRejectMonitor: React.FC = () => {
  const [filterSymbol, setFilterSymbol] = useState("");
  const [, layoutDispatch] = useLayoutContext();
  const tableDispatch = useStandardTableDispatch();
  const [isFlipped, setFlipped] = useState(false);
  const [tableData] = useStandardTableContext();
  const [formData] = useFormContext();
  const porCacheState = usePorCacheState();
  const porCacheDispatch = usePorCacheDispatch();
  const rejectCacheState = useRejectCacheState();
  const rejectCacheDispatch = useRejectCacheDispatch();
  const rejectCountState = useRejectCountState();
  const rejectCountDispatch = useRejectCountDispatch();
  const [recapData] = useRecapContext();
  const bulkJobDispatch = useBulkJobDispatch();
  const [isResubmitting, setIsResubmitting] = useState(false);
  const formDispatch = useFormDispatch();
  const [userData] = useUserContext();
  const [mpidState] = useMPIDOptionsContext();

  const selectedMpids: SelectOption[] = useMemo(() => {
    if (Array.isArray(formData[Forms.PV_MONITOR_MEMBER_TOP.key].fields[ApiResponseNames.mpid])) {
      return formData[Forms.PV_MONITOR_MEMBER_TOP.key].fields[ApiResponseNames.mpid];
    }
    return emptyMpids;
  }, [formData]);

  const selectedDateFilter: SelectOption = useMemo(() => {
    if (formData[Forms.PV_MONITOR_MEMBER_TOP.key].fields[PV_REJECT_TOP.rejectDate]) {
      return formData[Forms.PV_MONITOR_MEMBER_TOP.key].fields[PV_REJECT_TOP.rejectDate];
    }
    return REJECT_DATE_OPTIONS[DATE_FILTER.ALL];
  }, [formData]);

  useEffect(() => {
    if (Array.isArray(selectedMpids) && selectedMpids.length > 0) {
      porCacheDispatch({
        type: "START_POLLING",
        payload: {
          filterBy: POR_CACHE_FILTER_BY.MPID,
          keys: selectedMpids.map(mpid => mpid.label),
        },
      });
    } else {
      porCacheDispatch({
        type: "RESET_CACHE",
      });
    }
    return () => {
      porCacheDispatch({
        type: "RESET_CACHE",
      });
    };
  }, [selectedMpids, tableDispatch, porCacheDispatch]);

  useEffect(() => {
    if (Array.isArray(selectedMpids) && selectedMpids.length > 0 && selectedDateFilter?.value) {
      rejectCountDispatch({
        type: "START_POLLING",
        payload: {
          filter: {
            filterBy: REJECT_COUNT_FILTER_BY.CLIENT_COUNTS,
            criteria: selectedMpids.map(mpid => mpid.value) as string[],
            date: selectedDateFilter.value as DATE_FILTER,
          },
        },
      });
    } else {
      rejectCacheDispatch({
        type: "RESET_CACHE",
      });
      rejectCountDispatch({
        type: "RESET_CACHE",
      });
    }
    return () => {
      rejectCacheDispatch({
        type: "RESET_CACHE",
      });
      rejectCountDispatch({
        type: "RESET_CACHE",
      });
      tableDispatch({
        type: "DESELECT_ALL_ROWS",
        payload: { table: StandardTables.PV_REJECTS },
      });
    };
  }, [
    selectedMpids,
    tableDispatch,
    porCacheDispatch,
    rejectCacheDispatch,
    rejectCountDispatch,
    selectedDateFilter,
  ]);

  useEffect(
    () => () =>
      porCacheDispatch({
        type: "STOP_POLLING",
      }),
    [porCacheDispatch]
  );

  useEffect(() => {
    tableDispatch([
      {
        type: "SET_TABLE_DATA",
        payload: {
          table: StandardTables.PV_REQUESTS,
          data: Object.values(porCacheState.data)
            .filter(por => !!por[PORModel.SUBSCRIBED])
            .sort(
              (a, b) =>
                new Date(b[PORModel.LAST_UDPATE]).getTime() -
                new Date(a[PORModel.LAST_UDPATE]).getTime()
            ),
        },
      },
      {
        type: "SET_IS_LOADING",
        payload: { table: StandardTables.PV_REQUESTS, isLoading: porCacheState.isLoading },
      },
    ]);
  }, [porCacheState.data, porCacheState.isLoading, tableDispatch]);

  useEffect(() => {
    const actions = [];
    if (rejectCacheState.isLoading) {
      actions.push({
        type: "DESELECT_ALL_ROWS",
        payload: { table: StandardTables.PV_REJECTS },
      });
    }
    actions.push({
      type: "SET_TABLE_DATA",
      payload: { table: StandardTables.PV_REJECTS, data: Object.values(rejectCacheState.data) },
    });
    actions.push({
      type: "SET_IS_LOADING",
      payload: { table: StandardTables.PV_REJECTS, isLoading: rejectCacheState.isLoading },
    });
    tableDispatch(actions);
  }, [rejectCacheState.data, rejectCacheState.isLoading, tableDispatch]);

  const handleSetFilter = useCallback(
    ({ symbol, reject }: { symbol?: string; reject: string }) =>
      () => {
        if (Array.isArray(selectedMpids) && selectedDateFilter?.value) {
          rejectCacheDispatch({
            type: "START_POLLING",
            payload: {
              filter: {
                filterBy: REJECT_CACHE_FILTER_BY.MPID_SYMBOL,
                criteria: {
                  mpidToSymbols: selectedMpids.reduce((acc, mpid) => {
                    acc[mpid.label] = [symbol];
                    return acc;
                  }, {} as any),
                  rejectText: reject,
                  date: selectedDateFilter.value as DATE_FILTER,
                },
              },
            },
          });
        }
      },
    [rejectCacheDispatch, selectedDateFilter.value, selectedMpids]
  );

  useEffect((): ReturnType<EffectCallback> => {
    layoutDispatch({
      type: "SET_HIDE_LEFT",
      payload: true,
    });
    return () => {
      layoutDispatch({
        type: "SET_HIDE_LEFT",
        payload: false,
      });
    };
  }, [layoutDispatch]);

  const { dataArrayCounts, pieChartData, totalRejectCount } = useMemo(() => {
    const { dataArrayCounts, pieChartData, totalRejectCount, totalPriceCount, totalOverrideCount } =
      Object.entries(rejectCountState.counts).reduce(
        (acc, [symbol, count]) => {
          const subRows = [];
          if (count.priceCount > 0) {
            subRows.push({
              label: PV_REJECT_LABELS[REJ_PRICE_OO_RANGE],
              value: (
                <ArrayButton onClick={handleSetFilter({ symbol, reject: REJ_PRICE_OO_RANGE })}>
                  {count.priceCount}
                </ArrayButton>
              ),
              count: count.priceCount,
            });
          }
          if (count.priceOverrideCount > 0) {
            subRows.push({
              label: PV_REJECT_LABELS[REJ_PRICE_OO_OVERRIDE_RANGE],
              value: (
                <ArrayButton
                  onClick={handleSetFilter({ symbol, reject: REJ_PRICE_OO_OVERRIDE_RANGE })}
                >
                  {count.priceOverrideCount}
                </ArrayButton>
              ),
              count: count.priceOverrideCount,
            });
          }
          acc.dataArrayCounts.push({
            label: symbol,
            value: count.priceCount + count.priceOverrideCount,
            subRows,
          });
          acc.pieChartData[0].value += count.priceCount;
          acc.pieChartData[1].value += count.priceOverrideCount;
          acc.totalRejectCount += count.priceCount + count.priceOverrideCount;
          acc.totalPriceCount += count.priceCount;
          acc.totalOverrideCount += count.priceOverrideCount;
          return acc;
        },
        {
          dataArrayCounts: [],
          pieChartData: [
            { name: REJ_PRICE_OO_RANGE, value: 0 },
            { name: REJ_PRICE_OO_OVERRIDE_RANGE, value: 0 },
          ],
          totalRejectCount: 0,
          totalPriceCount: 0,
          totalOverrideCount: 0,
        } as {
          dataArrayCounts: DataArrayCount[];
          pieChartData: PieChartData;
          totalRejectCount: number;
          totalPriceCount: number;
          totalOverrideCount: number;
        }
      );
    dataArrayCounts.push({
      label: "<ALL>",
      value: totalRejectCount,
      subRows: [
        {
          label: PV_REJECT_LABELS[REJ_PRICE_OO_RANGE],
          value: (
            <ArrayButton onClick={handleSetFilter({ symbol: ALL_VAL, reject: REJ_PRICE_OO_RANGE })}>
              {totalPriceCount}
            </ArrayButton>
          ),
          count: totalPriceCount,
        },
        {
          label: PV_REJECT_LABELS[REJ_PRICE_OO_OVERRIDE_RANGE],
          value: (
            <ArrayButton
              onClick={handleSetFilter({ symbol: ALL_VAL, reject: REJ_PRICE_OO_OVERRIDE_RANGE })}
            >
              {totalOverrideCount}
            </ArrayButton>
          ),
          count: totalOverrideCount,
        },
      ],
    });
    dataArrayCounts.sort((a: DataArrayCount, b: DataArrayCount) => {
      if (a.label === "<ALL>") {
        return -1;
      } else if (b.label === "<ALL>") {
        return 1;
      }
      return b.value - a.value;
    });
    return {
      dataArrayCounts,
      pieChartData,
      totalRejectCount,
      totalPriceCount,
      totalOverrideCount,
    };
  }, [handleSetFilter, rejectCountState.counts]);

  const setSymbolFilter = useCallback((e: any) => {
    setFilterSymbol(e.value);
  }, []);

  const barChartData = useMemo(() => {
    const data = Object.entries(rejectCountState.counts).reduce((acc, [symbol, count]) => {
      acc[symbol] = {
        [REJ_PRICE_OO_RANGE]: count.priceCount,
        [REJ_PRICE_OO_OVERRIDE_RANGE]: count.priceOverrideCount,
      };
      return acc;
    }, {} as { [symbol: string]: { [rejMsg: string]: number } });
    const arrData = Object.entries(data).map(([key, val]) => {
      return { symbol: key, rejects: val };
    });
    arrData.sort((a, b) => {
      return (
        Object.values(b.rejects).reduce((acc, curr) => acc + curr, 0) -
        Object.values(a.rejects).reduce((acc, curr) => acc + curr, 0)
      );
    });
    const chartData = [];
    for (let i = 0; i < 5 && i < arrData.length; i++) {
      chartData.push({
        name: arrData[i].symbol,
        values: Object.entries(arrData[i].rejects).map(([rejMsg, count]) => ({
          name: PV_REJECT_LABELS[rejMsg as PV_REJECT_MESSAGE],
          value: count,
        })),
      });
    }
    const countOfAllOthers = arrData.slice(5, arrData.length).reduce((acc, curr) => {
      Object.entries(curr.rejects).forEach(([rejMsg, count]) => {
        if (!acc[rejMsg]) {
          acc[rejMsg] = 0;
        }
        acc[rejMsg] += count;
      });
      return acc;
    }, {} as { [rejMsg: string]: number });
    const vals = Object.values(countOfAllOthers);
    if (vals.length > 0 && vals.reduce((acc, curr) => acc + curr) > 0) {
      chartData.push({
        name: "Other",
        values: Object.entries(countOfAllOthers).map(([rejMsg, count]) => ({
          name: PV_REJECT_LABELS[rejMsg as PV_REJECT_MESSAGE],
          value: count,
        })),
      });
    }
    return chartData;
  }, [rejectCountState.counts]);

  const PORHeader = useCallback(
    () => (
      <StandardHeader
        title={
          <ServiceIndicatorWrapper>
            Parameter Requests
            <LimoLVCIndicator
              prefix={undefined}
              id={"por-indicator"}
              status={porCacheState.status}
            />
          </ServiceIndicatorWrapper>
        }
      />
    ),
    [porCacheState.status]
  );

  const createPORs = useCallback(() => {
    if (rejectCacheState.filter?.criteria.rejectText !== REJ_PRICE_OO_OVERRIDE_RANGE) {
      NotificationHub.send("danger", "POR action is not eligible for Price Out Of Range rejects");
    } else {
      const rows: PvReject[] = getSelectedRows(tableData[StandardTables.PV_REJECTS]);
      const porData: any[] = [];
      const porKeys = new Set();
      rows.forEach(row => {
        const key = `${row[PvRejectModel.ENTRY_MPID]}|${row[PvRejectModel.SYMBOL]}`;
        if (!porKeys.has(key)) {
          porKeys.add(key);
          porData.push({ symbol: row[PvRejectModel.SYMBOL], mpid: row[PvRejectModel.ENTRY_MPID] });
        }
      });

      const createPorSuccess = (data: WorkXResponse<PorProcessManyDto>) => {
        switch (data.body?.status) {
          case PorProcessManyStatus.SUCCESS: {
            NotificationHub.send("success", getSaveAllSuccessMessage(data.body.successful.length));
            tableDispatch({
              type: "ADD_TABLE_DATA_FIRST",
              payload: { table: StandardTables.PV_REQUESTS, data: data.body.successful },
            });
            break;
          }
          case PorProcessManyStatus.FAILED: {
            NotificationHub.send("danger", getSaveAllFailedMessage(data.body.failed.length), {
              subtitle: getSaveAllErrorSubtitle(data.errorMessages),
            });
            break;
          }
          case PorProcessManyStatus.PARTIAL: {
            NotificationHub.send(
              "warning",
              `${getSaveAllSuccessMessage(data.body.successful.length)} ${getSaveAllFailedMessage(
                data.body.failed.length
              )}`,
              {
                subtitle: getSaveAllErrorSubtitle(data.errorMessages),
              }
            );
            tableDispatch({
              type: "ADD_TABLE_DATA_FIRST",
              payload: { table: StandardTables.PV_REQUESTS, data: data.body.successful },
            });
            break;
          }
          default: {
            NotificationHub.send("danger", "Response for creating POR(s) is not recognized");
            break;
          }
        }
      };

      const createPorError = (err: PorServiceErrorResponse) => {
        NotificationHub.send("danger", "Error creating POR(s)", { subtitle: err.message });
      };

      doFetchWrapper(
        formatUrl(
          //user[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.pvrUrl],
          process.env.REACT_APP_URL_PVR_POR_SERVICE,
          "por/saveAll"
        ),
        {
          method: "post",
          headers: getHeaders(),
          body: JSON.stringify(porData),
        },
        createPorSuccess,
        createPorError
      );
    }
  }, [tableData, tableDispatch, rejectCacheState.filter]);

  const lateTradeModifier = useMemo(() => {
    return formData[Forms.PVR_RESUBMIT.key].fields[FieldNames.lateTradeModifier]?.value;
  }, [formData]);

  const resubmitPriceRange = useCallback(() => {
    const rows: PvReject[] = getSelectedRows(tableData[StandardTables.PV_REJECTS]);
    const rejectIds = rows.map(row => row[PvRejectModel.ID]);
    const resubmitSuccess = (response: WorkXResponse) => {
      const body: BulkResubmitResponseDTO = response.body;
      setIsResubmitting(false);
      if (body.numProcessing > 0) {
        if (body.numWorkxRejects > 0) {
          sendWorkXResponseNotification({
            response,
            color: NotificationColor.WARN,
            title: `${getResubmitSuccessMessage(
              body.numProcessing,
              ResubmitMessageSuffix.PRICE_OVERRIDE
            )} ${getResubmitFailedMessage(
              body.numWorkxRejects,
              ResubmitMessageSuffix.PRICE_OVERRIDE
            )}`,
          });
        } else {
          sendWorkXResponseNotification({
            response,
            color: NotificationColor.SUCCESS,
            title: `${getResubmitSuccessMessage(
              body.numProcessing,
              ResubmitMessageSuffix.PRICE_OVERRIDE
            )}`,
          });
        }
        bulkJobDispatch({ type: "ADD_JOB", payload: body.data });
      } else {
        sendWorkXResponseNotification({
          response,
          color: NotificationColor.ERROR,
          title: `${getResubmitFailedMessage(
            body.numWorkxRejects,
            ResubmitMessageSuffix.PRICE_OVERRIDE
          )}`,
        });
      }
    };

    const resubmitError = (response: WorkXResponse) => {
      setIsResubmitting(false);
      if (response.bodyName === WorkXResponseDTO.BulkResubmitResponseDTO) {
        const body = response.body as BulkResubmitResponseDTO;
        sendWorkXResponseNotification({
          response,
          color: NotificationColor.ERROR,
          title: `${getResubmitFailedMessage(
            body.numWorkxRejects,
            ResubmitMessageSuffix.PRICE_OVERRIDE
          )}`,
        });
      } else {
        NotificationHub.send(
          "danger",
          getResubmitErrorMessage(ResubmitMessageSuffix.PRICE_OVERRIDE),
          {
            subtitle:
              response?.errorMessages?.length !== undefined && response.errorMessages.length > 0
                ? response?.errorMessages?.[0]
                : undefined,
          }
        );
      }
    };
    setIsResubmitting(true);
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_PVR_ACT_WS, "bulk/resubmit/priceOverride"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify({ rejectIds, lateTradeModifier }),
      },
      resubmitSuccess,
      resubmitError
    );
    tableDispatch({
      type: "DESELECT_ALL_ROWS",
      payload: { table: StandardTables.PV_REJECTS },
    });
  }, [bulkJobDispatch, lateTradeModifier, tableData, tableDispatch]);

  const resubmitOverrideRange = useCallback(() => {
    const rows: PvReject[] = getSelectedRows(tableData[StandardTables.PV_REJECTS]);
    const rejectIds = rows.map(row => row[PvRejectModel.ID]);
    const resubmitSuccess = (response: WorkXResponse) => {
      const body: BulkResubmitResponseDTO = response.body;
      setIsResubmitting(false);
      if (body.numProcessing > 0) {
        if (body.numWorkxRejects > 0) {
          sendWorkXResponseNotification({
            response,
            color: NotificationColor.WARN,
            title: `${getResubmitSuccessMessage(
              body.numProcessing,
              ResubmitMessageSuffix.PRICE_VALIDATION_OFF
            )} ${getResubmitFailedMessage(
              body.numWorkxRejects,
              ResubmitMessageSuffix.PRICE_VALIDATION_OFF
            )}`,
          });
        } else {
          sendWorkXResponseNotification({
            response,
            color: NotificationColor.SUCCESS,
            title: `${getResubmitSuccessMessage(
              body.numProcessing,
              ResubmitMessageSuffix.PRICE_VALIDATION_OFF
            )}`,
          });
        }
        bulkJobDispatch({ type: "ADD_JOB", payload: body.data });
      } else {
        sendWorkXResponseNotification({
          response,
          color: NotificationColor.ERROR,
          title: `${getResubmitFailedMessage(
            body.numWorkxRejects,
            ResubmitMessageSuffix.PRICE_VALIDATION_OFF
          )}`,
        });
      }
    };

    const resubmitError = (response: WorkXResponse) => {
      setIsResubmitting(false);
      if (response.bodyName === WorkXResponseDTO.BulkResubmitResponseDTO) {
        const body = response.body as BulkResubmitResponseDTO;
        sendWorkXResponseNotification({
          response,
          color: NotificationColor.ERROR,
          title: `${getResubmitFailedMessage(
            body.numWorkxRejects,
            ResubmitMessageSuffix.PRICE_VALIDATION_OFF
          )}`,
        });
      } else {
        NotificationHub.send(
          "danger",
          getResubmitErrorMessage(ResubmitMessageSuffix.PRICE_VALIDATION_OFF),
          {
            subtitle:
              response?.errorMessages?.length !== undefined && response.errorMessages.length > 0
                ? response?.errorMessages?.[0]
                : undefined,
          }
        );
      }
    };
    setIsResubmitting(true);
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_PVR_ACT_WS, "bulk/resubmit/priceValidationOff"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify({ rejectIds, lateTradeModifier }),
      },
      resubmitSuccess,
      resubmitError
    );
    tableDispatch({
      type: "DESELECT_ALL_ROWS",
      payload: { table: StandardTables.PV_REJECTS },
    });
  }, [bulkJobDispatch, tableData, tableDispatch, lateTradeModifier]);

  const { uniqueSelectedPorRejects, numRejectsSelected } = useMemo(() => {
    const symbolSet = new Set<string>();
    const selected = getSelectedRows(tableData[StandardTables.PV_REJECTS]) as PvReject[];
    const uniqueSelectedPorRejects = selected.reduce((acc, curr) => {
      if (curr) {
        if (!symbolSet.has(curr.symbol)) {
          acc++;
          symbolSet.add(curr.symbol);
        }
      } else {
        console.warn("Invalid row found in selected rows: " + curr);
      }
      return acc;
    }, 0);
    return { uniqueSelectedPorRejects, numRejectsSelected: selected.length };
  }, [tableData]);

  const ResubmitButttonConfirm = useCallback(() => {
    return (
      <Header size={3}>Are you sure you want to Resubmit {numRejectsSelected} Reject(s)?</Header>
    );
  }, [numRejectsSelected]);

  const PorButttonConfirm = useCallback(() => {
    return <PorButtonHeader count={uniqueSelectedPorRejects} />;
  }, [uniqueSelectedPorRejects]);

  const handleCloseMenu = useCallback(() => {
    formDispatch({
      type: "RESET_FORM",
      payload: { form: Forms.PVR_RESUBMIT },
    });
  }, [formDispatch]);

  const RejectButtons = useMemo(() => {
    const tableButtons = [];
    if (userData.allowed.actions[ViewActions.PVR_CLIENT_MONITOR_RESUBMIT]) {
      tableButtons.push({
        icon: "arrow-up",
        text: TableButtonLabel.RESUBMIT,
        actionId: TableButtonAction.PVR_RESUBMIT,
        canConfirm: true,
        allowConfirm: true,
        requireSelect: true,
        allowMultiSelect: true,
        header: ResubmitButttonConfirm,
        onClick:
          rejectCacheState.filter?.criteria.rejectText === REJ_PRICE_OO_OVERRIDE_RANGE
            ? resubmitOverrideRange
            : resubmitPriceRange,
        isDisabled: isResubmitting,
        loading: isResubmitting,
        onCloseConfirm: handleCloseMenu,
      });
    }
    if (userData.allowed.actions[ViewActions.PVR_CLIENT_MONITOR_POR]) {
      tableButtons.push({
        icon: "lock-open",
        text: TableButtonLabel.POR,
        actionId: TableButtonAction.POR,
        canConfirm: true,
        header: PorButttonConfirm,
        allowConfirm: true,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: createPORs,
        confirmText: "YES",
      });
    }
    const buttons =
      tableButtons.length > 0
        ? [<TableButtons table={StandardTables.PV_REJECTS} buttons={tableButtons} />]
        : [];
    if (userData.allowed.actions[ViewActions.TRADE_REPORTING]) {
      buttons.push(<CloseButton table={StandardTables.PV_REJECTS} />);
    }
    buttons.push(<RecapButton table={StandardTables.PV_REJECTS} />);
    return buttons;
  }, [
    userData.allowed.actions,
    ResubmitButttonConfirm,
    rejectCacheState.filter?.criteria.rejectText,
    resubmitOverrideRange,
    resubmitPriceRange,
    isResubmitting,
    handleCloseMenu,
    PorButttonConfirm,
    createPORs,
  ]);

  const handlePieChartClick = useCallback(
    (event: React.MouseEvent, data: { name: string }) => {
      if (Array.isArray(selectedMpids) && selectedDateFilter?.value) {
        tableDispatch({
          type: "DESELECT_ALL_ROWS",
          payload: { table: StandardTables.PV_REJECTS },
        });
        rejectCacheDispatch({
          type: "START_POLLING",
          payload: {
            filter: {
              filterBy: REJECT_CACHE_FILTER_BY.MPID,
              criteria: {
                mpids: selectedMpids.map(mpid => mpid.value as string),
                rejectText: data.name,
                date: selectedDateFilter.value as DATE_FILTER,
              },
            },
          },
        });
      }
    },
    [rejectCacheDispatch, selectedDateFilter.value, selectedMpids, tableDispatch]
  );

  // const handleBarChartClick = useCallback(
  //   (event: any, data: any) => {
  //     const reject = data.name === "Price" ? REJ_PRICE_OO_RANGE : REJ_PRICE_OO_OVERRIDE_RANGE;
  //     handleSetFilter({ symbol: data.name, reject })();
  //   },
  //   [handleSetFilter]
  // );

  const RejectBarChart = useMemo(() => {
    return (
      <FlipChartContainer>
        <ChartContainer>
          <BarChart data={barChartData} legend={true} /*onClick={handleBarChartClick}*/ />
        </ChartContainer>
      </FlipChartContainer>
    );
  }, [barChartData /*, handleBarChartClick*/]);

  const RejectDataArray = useMemo(() => {
    if (totalRejectCount > 0) {
      let filteredCounts = filterForDataArray(dataArrayCounts, filterSymbol);
      return (
        <>
          <FormField
            value={filterSymbol}
            onChange={setSymbolFilter}
            size={"sm"}
            placeholder="Symbol"
          />
          <ArrayWrapper>
            <DataArray data={filteredCounts} expandable={true} boldValues={false} />
          </ArrayWrapper>
        </>
      );
    }
    return <React.Fragment></React.Fragment>;
  }, [totalRejectCount, dataArrayCounts, filterSymbol, setSymbolFilter]);

  const toggleFlipped = useCallback(() => {
    setFlipped(!isFlipped);
  }, [isFlipped]);

  const RejectTableHeader = useCallback(() => {
    let title = "Price Rejects";
    if (rejectCacheState.filter?.criteria?.rejectText === REJ_PRICE_OO_OVERRIDE_RANGE) {
      title = "Price Override Rejects";
    }
    if (rejectCacheState.filter?.filterBy === REJECT_CACHE_FILTER_BY.MPID_SYMBOL) {
      const symbols = Object.values(rejectCacheState.filter?.criteria?.mpidToSymbols)?.[0];
      if (symbols) {
        if (symbols.length === 1) {
          if (symbols[0] !== ALL_VAL) {
            title += ` for ${symbols[0]}`;
          }
        } else {
          title += ` for ${symbols.length} Symbols`;
        }
      }
    }

    return (
      <StandardHeader
        title={
          <ServiceIndicatorWrapper>
            {title}
            <LimoLVCIndicator
              prefix={undefined}
              id={"rej-cache-indicator"}
              status={rejectCacheState.status}
            />
          </ServiceIndicatorWrapper>
        }
      />
    );
  }, [rejectCacheState.filter, rejectCacheState.status]);

  if (!mpidState.userMPIDOptions.isReady) {
    return <PageLoader />;
  }

  return (
    <Middle>
      <Grid>
        <PorClientMonitorInputs />
        <ProMonitorRejectPieChart
          pieChartData={pieChartData}
          totalRejectCount={totalRejectCount}
          selectedMpids={selectedMpids}
          selectedDateFilter={selectedDateFilter}
        />

        <ArraySection>
          <LoaderWrapper>
            <Loader isLoading={rejectCountState.isLoading}>
              {totalRejectCount === 0 ? NoRejectsFillerJsx : <></>}
              <FlipCard
                isFlipped={isFlipped}
                front={
                  <RejectsFlipCardSection
                    totalRejectCount={totalRejectCount}
                    toggleFlipped={toggleFlipped}
                    cacheStatus={rejectCountState.status}
                  >
                    {RejectDataArray}
                  </RejectsFlipCardSection>
                }
                back={
                  <RejectsFlipCardSection
                    totalRejectCount={totalRejectCount}
                    toggleFlipped={toggleFlipped}
                    cacheStatus={rejectCountState.status}
                  >
                    {RejectBarChart}
                  </RejectsFlipCardSection>
                }
              />
            </Loader>
          </LoaderWrapper>
        </ArraySection>

        <TimerWrapper>
          <PorWindowTimer marginTopPx={1} />
        </TimerWrapper>
        <RequestTableWrapper>
          <StandardTable
            Header={undefined}
            table={StandardTables.PV_REQUESTS}
            enableLayoutExport={true}
            isSingleSelect={false}
            isColumnsVirtualized={false}
            subHeader={undefined}
            hideRowCount={false}
            hideSelectedCount={false}
            hideQueryDate={undefined}
            headerMenu={undefined}
            exportCallback={undefined}
            exportFileName={undefined}
            additionalRowClick={undefined}
            isEditableTableEnabled={undefined}
            style={undefined}
            isRowSelectEnabled={false}
            header={PORHeader}
            isFilterable={false}
          />
        </RequestTableWrapper>

        <TableWrapper>
          <StandardTable
            header={RejectTableHeader}
            table={StandardTables.PV_REJECTS}
            enableLayoutExport={true}
            isSingleSelect={false}
            isColumnsVirtualized={false}
            isFilterable={true}
            subHeader={undefined}
            hideRowCount={false}
            hideSelectedCount={false}
            hideQueryDate={undefined}
            headerMenu={RejectButtons}
            exportCallback={undefined}
            exportFileName={undefined}
            additionalRowClick={undefined}
            isEditableTableEnabled={undefined}
            style={undefined}
            isRowSelectEnabled={undefined}
            Header={undefined}
          />
        </TableWrapper>
      </Grid>
      <TradeDetailRecap widthFactor={recapData.trades.length} />
    </Middle>
  );
};
