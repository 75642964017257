import { Tag } from "@nef/core";
import { POR, PORModel, PORStatus, PvReject, PvRejectModel } from "../member/constant";
import moment from "moment-timezone";

export const PVR_DATE_FORMAT = "YYYY-MM-DD";
export const PVR_TIME_FORMAT = "HH:mm:ss";
export const PVR_DATE_TIME_FORMAT = `${PVR_DATE_FORMAT} ${PVR_TIME_FORMAT}`;

export const pvLiteColumns = [
  { Header: "MPID", id: PORModel.MPID, accessor: PORModel.MPID, width: 75 },
  {
    Header: "Symbol",
    id: PvRejectModel.SYMBOL,
    accessor: PvRejectModel.SYMBOL,
    width: 90,
  },
  {
    Header: "Request Status",
    id: PORModel.REQUEST_STATUS,
    accessor: PORModel.REQUEST_STATUS,
    width: 115,
    Cell: ({ row }: { row: { original: PvReject } }) => {
      let color = undefined;
      if (row.original[PORModel.REQUEST_STATUS] === PORStatus.DENIED) {
        color = "danger";
      } else if (row.original[PORModel.REQUEST_STATUS] === PORStatus.PENDING) {
        color = "primary";
      } else if (row.original[PORModel.REQUEST_STATUS] === PORStatus.EXPIRED) {
        color = "warning";
      } else if (row.original[PORModel.REQUEST_STATUS] === PORStatus.APPROVED) {
        color = "success";
      }
      return color === undefined ? (
        <></>
      ) : (
        <Tag color={color}>{row.original[PORModel.REQUEST_STATUS]}</Tag>
      );
    },
  },
  {
    Header: "Requestor",
    id: PORModel.REQUESTOR_EMAIL,
    accessor: PORModel.REQUESTOR_EMAIL,
    width: 150,
  },
  { Header: "Request ID", id: PORModel.ID, accessor: PORModel.ID, width: 80 },
  {
    Header: "Last Update",
    id: PORModel.LAST_UDPATE,
    accessor: (row: POR) => {
      if (row[PORModel.LAST_UDPATE]) {
        return moment(row[PORModel.LAST_UDPATE]).format(PVR_DATE_TIME_FORMAT);
      }
      return "N/A";
    },
    width: 160,
  },
];
