import { NotificationHub } from "@nef/core";
import {
  AlertEditOptions,
  AlertEditValues,
  Forms,
  LIMIT_SIDE_BREACH_VALUES,
} from "components/fields/fieldConstants";
import { useFormContext, useFormDispatch } from "components/form";
import { SettingsWrapper } from "components/settings";
import {
  StandardHeader,
  StandardTable,
  getSelectedRows,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { getColumnHeadersAndAccessors } from "components/standardTable/layoutExportButtons";
import { convertToCSV, createAndDownloadTemplate } from "components/standardTable/utils";
import { useUserContext } from "components/user";
import { getHeaders } from "keycloak";
import { useCallback, useEffect, useMemo, useState } from "react";
import { formatUrl } from "utils/js.utils";
import { ViewActions } from "viewConstants";
import { PvrCreateModal, PvrEditModal, PvrAlertCreateValidation } from ".";
import { doFetchWrapper } from "../../../network";
import { usePvrConfigContext, usePvrConfigDispatch } from "./configContext";
import { PVR_CONFIGURATION_ENTITY } from "./fields";
import { PvrAlertTableButtons } from "./pvrAlertTableButtons";

const IdListActions = {
  DELETE: "delete",
  DISABLE: "disable",
  ENABLE: "enable",
};

const tableStyle = { height: "100%" };

export const PvrAlertSettingsTable = ({
  table,
  createTable,
  editTable,
  newForm,
  editForm,
  NewForm,
  EditForm,
}) => {
  const [tableData] = useStandardTableContext();
  const tableDispatch = useStandardTableDispatch();
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [isNewModalVisible, setNewModalVisible] = useState(false);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [userData] = useUserContext();
  const [configs] = usePvrConfigContext();
  const configDispatch = usePvrConfigDispatch();

  useEffect(() => {
    tableDispatch({
      type: "SET_TABLE_DATA",
      payload: {
        table,
        data: configs.data,
      },
    });
  }, [table, tableDispatch, configs.data]);

  useEffect(() => {
    tableDispatch({
      type: "SET_IS_LOADING",
      payload: {
        table,
        isLoading: configs.isLoading,
      },
    });
  }, [configs.isLoading, table, tableDispatch]);

  const handleClickNew = useCallback(() => {
    formDispatch({
      type: "INIT_FORM_VALIDATION",
      payload: { form: newForm, entitlements: userData.entitlements },
    });
    setNewModalVisible(true);
  }, [formDispatch, newForm, userData.entitlements]);

  const handleClickEdit = useCallback(() => {
    setEditModalVisible(true);
  }, []);

  const handleCloseNew = useCallback(() => {
    formDispatch({ type: "RESET_FORM", payload: { form: newForm } });
    setNewModalVisible(false);
  }, [formDispatch, newForm]);

  const handleCloseEdit = useCallback(() => {
    formDispatch({ type: "RESET_FORM", payload: { form: editForm } });
    setEditModalVisible(false);
  }, [editForm, formDispatch]);

  const deleteManyCallback = useCallback(() => {
    NotificationHub.send("success", "Deleted alert configuration(s)");
    configDispatch({
      type: "SET_REQUESTING",
      payload: true,
    });
    tableDispatch({
      type: "DESELECT_ALL_ROWS",
      payload: { table },
    });
  }, [configDispatch, table, tableDispatch]);

  const handleClickCopy = useCallback(() => {
    const index = Object.keys(tableData[table].selected).find(
      key => tableData[table].selected[key] === true
    );
    const row = tableData[table].data[index];
    const excludedFields = [PVR_CONFIGURATION_ENTITY.mpid];
    const fields = Object.keys(row).reduce((acc, key) => {
      if (!excludedFields.includes(key)) {
        switch (key) {
          default:
            acc[key] = row[key];
            break;
        }
      }
      return acc;
    }, {});

    formDispatch([
      {
        type: "SET_FORM_VALUES",
        payload: { form: newForm, fields },
      },
      {
        type: "INIT_FORM_VALIDATION",
        payload: { form: newForm, entitlements: userData.entitlements },
      },
    ]);
    setNewModalVisible(true);
  }, [formDispatch, newForm, table, tableData, userData.entitlements]);

  const idListActionCallback = useCallback(
    idListAction => json => {
      const actionText = {
        [IdListActions.DISABLE]: "Disabled",
        [IdListActions.ENABLE]: "Enabled",
      };
      NotificationHub.send("success", `${actionText[idListAction]} alert configuration(s)`);
      configDispatch({
        type: "SET_REQUESTING",
        payload: true,
      });
      tableDispatch({
        type: "DESELECT_ALL_ROWS",
        payload: { table },
      });
    },
    [configDispatch, table, tableDispatch]
  );

  const idListActionError = useCallback(
    idListAction => () => {
      const actionText = {
        [IdListActions.DELETE]: "delete",
        [IdListActions.DISABLE]: "disable",
        [IdListActions.ENABLE]: "enable",
      };
      tableDispatch([
        {
          type: "SET_IS_LOADING",
          payload: { table, isLoading: false },
        },
        {
          type: "DESELECT_ALL_ROWS",
          payload: { table },
        },
      ]);
      NotificationHub.send(
        "danger",
        `Failed to ${actionText[idListAction]} alert configuration(s)`
      );
    },
    [tableDispatch, table]
  );

  const handleClickIdListAction = useCallback(
    action => () => {
      const selected = getSelectedRows(tableData[table]);
      if (Array.isArray(selected)) {
        let path, successCb, errorCb, method;
        switch (action) {
          case IdListActions.DELETE:
            path = "alert/configuration/deleteMany";
            successCb = deleteManyCallback;
            errorCb = idListActionError(IdListActions.DELETE);
            method = "delete";
            break;
          case IdListActions.DISABLE:
            path = "alert/configuration/disable";
            successCb = idListActionCallback(IdListActions.DISABLE);
            errorCb = idListActionError(IdListActions.DISABLE);
            method = "put";
            break;
          case IdListActions.ENABLE:
            path = "alert/configuration/enable";
            successCb = idListActionCallback(IdListActions.ENABLE);
            errorCb = idListActionError(IdListActions.ENABLE);
            method = "put";
            break;
          default:
            return;
        }
        doFetchWrapper(
          formatUrl(process.env.REACT_APP_URL_PVR_ALERT_SERVICE, path),
          {
            method,
            headers: getHeaders(),
            body: JSON.stringify(selected.map(row => row.id)),
          },
          successCb,
          errorCb
        );
      }
    },
    [deleteManyCallback, idListActionCallback, idListActionError, tableData, table]
  );

  const tableButtons = useMemo(() => {
    return (
      <PvrAlertTableButtons
        table={table}
        onClickNew={handleClickNew}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickIdListAction(IdListActions.DELETE)}
        onClickCopy={handleClickCopy}
        onClickDisable={handleClickIdListAction(IdListActions.DISABLE)}
        onClickEnable={handleClickIdListAction(IdListActions.ENABLE)}
      />
    );
  }, [table, handleClickNew, handleClickEdit, handleClickCopy, handleClickIdListAction]);

  const exportSuccess = useCallback(
    json => {
      const { accessors, headers } = getColumnHeadersAndAccessors(table, tableData, true);
      createAndDownloadTemplate(`${table}.csv`, convertToCSV(headers, json, accessors)).then(() =>
        NotificationHub.send("success", "Export request has been completed")
      );
    },
    [tableData, table]
  );

  const exportError = useCallback(error => {
    NotificationHub.send(
      "danger",
      "A problem occured while exporting Post-Trade Risk Alert Configurations."
    );
  }, []);

  const handleSupervisorExport = useCallback(() => {
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_PVR_ALERT_SERVICE, "alert/configuration"),
      {
        method: "get",
        headers: getHeaders(),
      },
      exportSuccess,
      exportError
    );
  }, [exportSuccess, exportError]);

  const Header = useCallback(() => <StandardHeader title="Alert Configurations" />, []);
  return (
    <>
      <SettingsWrapper
        header="Price Reject Override (PRO): Alerts"
        subheader="Configure Price Reject Override Alerts"
        errorMessage={formData[Forms.SETTINGS_TR.key].globalErrorMessage}
        hasSave={false}
        disableFormWrapperClass={true}
      >
        {/* the height of the tables is further specified below via style.height  */}
        <StandardTable
          header={Header}
          table={table}
          enableLayoutExport={true}
          exportCallback={
            userData.allowed.actions[ViewActions.SUPERVISOR] ? handleSupervisorExport : undefined
          }
          id={"ptr-alert-table"}
          isSingleSelect={false}
          isColumnsVirtualized={false}
          isFilterable={true}
          style={tableStyle}
          headerMenu={tableButtons}
        />
      </SettingsWrapper>
      <PvrEditModal
        form={editForm}
        validationTable={editTable}
        riskConfigTable={table}
        RiskAlertForm={EditForm}
        ValidationTable={PvrAlertCreateValidation}
        isVisible={isEditModalVisible}
        onClose={handleCloseEdit}
      />
      <PvrCreateModal
        form={newForm}
        table={createTable}
        RiskAlertForm={NewForm}
        ValidationTable={PvrAlertCreateValidation}
        isVisible={isNewModalVisible}
        onClose={handleCloseNew}
      />
    </>
  );
};
