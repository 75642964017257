import { REJ_PRICE_OO_OVERRIDE_RANGE } from "components/pvRejects/constant";
import React, { useCallback, useMemo } from "react";
import { getKeyFromMpidAndSymbol, useRejectCountState } from "./rejectCountCache";
import { REJECT_CACHE_FILTER_BY, useRejectCacheDispatch } from "./rejectCache";
import { DATE_FILTER, PV_REJECT_TOP } from "./constant";
import { SpinnerIcon } from "components/styled";
import styled from "styled-components";
import { useFormContext } from "components/form";
import { Forms, REJECT_DATE_OPTIONS } from "components/fields";
import { SelectOption } from "types";

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

export const RejectCol: React.FC<{ mpid: string; symbol: string }> = ({ mpid, symbol }) => {
  const rejectCountState = useRejectCountState();
  const rejectCacheDispatch = useRejectCacheDispatch();
  const [formData] = useFormContext();

  const count = useMemo(() => {
    return rejectCountState.data[getKeyFromMpidAndSymbol(mpid, symbol)]?.priceOverrideCount || 0;
  }, [mpid, rejectCountState.data, symbol]);

  const selectedDateFilter: SelectOption = useMemo(() => {
    if (formData[Forms.PV_MONITOR_MEMBER_TOP.key].fields[PV_REJECT_TOP.rejectDate]) {
      return formData[Forms.PV_MONITOR_MEMBER_TOP.key].fields[PV_REJECT_TOP.rejectDate];
    }
    return REJECT_DATE_OPTIONS[DATE_FILTER.ALL];
  }, [formData]);

  const handleClickRejects = useCallback(() => {
    rejectCacheDispatch({
      type: "START_POLLING",
      payload: {
        filter: {
          filterBy: REJECT_CACHE_FILTER_BY.MPID_SYMBOL,
          criteria: {
            mpidToSymbols: { [mpid]: [symbol] },
            rejectText: REJ_PRICE_OO_OVERRIDE_RANGE,
            date: selectedDateFilter.value as DATE_FILTER,
          },
        },
      },
    });
  }, [mpid, rejectCacheDispatch, symbol, selectedDateFilter]);

  if (rejectCountState.isLoading) {
    return (
      <Center>
        <SpinnerIcon />
      </Center>
    );
  } else {
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return <a onClick={handleClickRejects}>{count}</a>;
  }
};
