import { Forms } from "components/fields/fieldConstants";
import { StandardTables } from "wksConstants";
import * as buttons from "components/reportScan/tables/buttons";

export const Views = {
  REPORT: "report",
  SCAN: "scan",
  REJECTS: "rejects",
  UPLOAD: "upload",
  QUERY_TOOL: "query",
  POST_TRADE_RISK: "limit",
  REAL_TIME_STATS: "stats",
  COMPLIANCE_DB: "complianceDashboard",
  SETTINGS: "settings",
  SETTINGS_LIMO: "settingsLimo",
  PTR_AUDIT_TRAIL: "ptrAudt",
  TRADER: "trader",
  REFERENCE_DATA_CLEARING: "referenceDataClearing",
  REFERENCE_DATA_AGU: "referenceDataAGU",
  REFERENCE_DATA_CUSIP: "referenceDataCUSIP",
  NO_ACCESS: "noAccess",
  FOUR_OH_THREE: "403",
  RIGHT: "right",
  FOUR_OH_NINE: "409",
  RISK_SETTINGS_UPLOAD: "riskSettingsUpload",
  PTR_ALERTS: "postTradeRiskAlerts",
  PTR_ALERTS_AGU: "postTradeRiskAguAlerts",
  PTR_EMAILS: "postTradeRiskEmailManager",
  EQRC_RULES: "eqrcRulesSettings",
  EQRC_MONITOR: "eqrc",
  ORDER_ENTRY: "orders",
  KEYCLOAK_EXPORT: "keycloakExport",
  PVR_CLIENT_MONITOR: "pvrMonitor",
  PVR_LITE_MONITOR: "pvrLiteMonitor",
  PVR_FINRA_MONITOR: "pvrFinra",
  PVR_ALERTS: "pvrAlerts",
  PVR_EMAILS: "pvrEmails",
};

export const ViewActions = {
  PTR_TRADES: "postTradeRiskTrades",
  PTR_CONFIG: "postTradeRiskConfig",
  TRADE_REPORTING: "tradeReporting",
  SUPERVISOR: "supervisor",
  EQRC_RULES_WRITE: "eqrcRulesTableWrite",
  EQRC_MONITOR_SAFETY_SWITCH: "eqrcMonitorSafetySwitch",
  ORDER_ENTRY: "orderEntry",
  PVR_CLIENT_MONITOR_POR: "pvrClientMonitorPor",
  PVR_CLIENT_MONITOR_RESUBMIT: "pvrClientMonitorResubmit",
  PVR_LITE_MONITOR_POR: "pvrLiteMonitorPor",
  FINRA_PRO: "finraPro",
};

export const ViewForm = {
  DEFAULT: "default",
  MATCH: "match",
  MODIFY: "modify",
  COPY: "copy",
  REPAIR: "repair",
};

export const ViewForms = {
  [Views.REPORT]: {
    [ViewForm.DEFAULT]: Forms.TR_REPORT,
    [ViewForm.MATCH]: Forms.TR_MATCH,
    [ViewForm.MODIFY]: Forms.TR_MODIFY,
    [ViewForm.COPY]: Forms.TR_COPY,
    [ViewForm.REPAIR]: Forms.TR_REPAIR,
  },
  [Views.SCAN]: {
    [ViewForm.DEFAULT]: Forms.TR_SCAN,
    [ViewForm.MATCH]: Forms.TR_MATCH,
    [ViewForm.MODIFY]: Forms.TR_MODIFY,
    [ViewForm.COPY]: Forms.TR_COPY,
    [ViewForm.REPAIR]: Forms.TR_REPAIR,
  },
  [Views.REJECTS]: {
    [ViewForm.DEFAULT]: Forms.TR_REJECTS,
    [ViewForm.REPAIR]: Forms.TR_REPAIR,
  },
  [Views.UPLOAD]: {
    [ViewForm.DEFAULT]: Forms.UPLOAD_REPAIR,
    [ViewForm.REPAIR]: Forms.UPLOAD_REPAIR,
  },
  [Views.QUERY_TOOL]: { [ViewForm.DEFAULT]: Forms.QUERY_TOOL },
  [Views.POST_TRADE_RISK]: { [ViewForm.DEFAULT]: null },
  [Views.REAL_TIME_STATS]: {
    [ViewForm.DEFAULT]: Forms.ST_MODIFY,
    [ViewForm.MATCH]: Forms.ST_MATCH,
    [ViewForm.MODIFY]: Forms.ST_MODIFY,
    [ViewForm.REPAIR]: Forms.ST_REPAIR,
    [ViewForm.COPY]: Forms.ST_COPY,
  },
  [Views.HISTORICAL_STATS]: { [ViewForm.DEFAULT]: null },
  [Views.COMPLIANCCE_DB]: { [ViewForm.DEFAULT]: null },
  [Views.SETTINGS]: { [ViewForm.DEFAULT]: null },
  [Views.TRADER]: { [ViewForm.DEFAULT]: null },
  [Views.REFERENCE_DATA_CLEARING]: {
    [ViewForm.DEFAULT]: Forms.RD_CLEARING,
  },
  [Views.REFERENCE_DATA_AGU]: {
    [ViewForm.DEFAULT]: Forms.RD_AGU,
  },
  [Views.REFERENCE_DATA_CUSIP]: {
    [ViewForm.DEFAULT]: Forms.RD_CUSIP,
  },
  [Views.ORDER_ENTRY]: {
    [ViewForm.DEFAULT]: Forms.ORDER_ENTRY,
  },
};

export const ViewTable = {
  [Views.REPORT]: StandardTables.TR_REPORT,
  [Views.SCAN]: StandardTables.TR_SCAN,
  [Views.REJECTS]: StandardTables.TR_REJECTS,
  [Views.REAL_TIME_STATS]: StandardTables.ST_MIDDLE,
  [Views.UPLOAD]: StandardTables.UPLOAD_ERRORS,
  [Views.REFERENCE_DATA_CLEARING]: StandardTables.RD_CLEARING,
  [Views.REFERENCE_DATA_AGU]: StandardTables.RD_AGU,
  [Views.REFERENCE_DATA_CUSIP]: StandardTables.RD_CUSIP,
};

export const tableTitles = {
  [Views.REPORT]: "My Reported Trades",
  [Views.SCAN]: "Scan Results",
  [Views.REJECTS]: "Rejected Scan Results",
  [Views.REFERENCE_DATA_CLEARING]: `${Forms.RD_CLEARING.label} Scan Results`,
  [Views.REFERENCE_DATA_AGU]: `${Forms.RD_AGU.label} Scan Results`,
  [Views.REFERENCE_DATA_CUSIP]: `${Forms.RD_CUSIP.label} Scan Results`,
};

export const tableButtons = {
  [Views.REPORT]: buttons.ReportButtons,
  [Views.SCAN]: buttons.ScanButtons,
  [Views.REJECTS]: buttons.RejectsButtons,
  [Views.REFERENCE_DATA_CLEARING]: null,
  [Views.REFERENCE_DATA_AGU]: null,
  [Views.REFERENCE_DATA_CUSIP]: null,
};

export const messages = {
  streamDown: "Encountered Stream Error",
};
