import { Section, SectionBody } from "@nef/core";
import { PaddedSectionHeader, PaddedSectionHeader2 } from "./tile.front";

export const TileBack = ({
  HeaderBox,
  title1,
  chart,
}: {
  HeaderBox: any;
  title1: string;
  chart: any;
}) => {
  return (
    <>
      <PaddedSectionHeader>{HeaderBox}</PaddedSectionHeader>
      <Section>
        <PaddedSectionHeader2>{title1}</PaddedSectionHeader2>
        <SectionBody>{chart}</SectionBody>
      </Section>
    </>
  );
};
