import { BarChart } from "@nef/charts";
import { HeaderBox } from "./tile.container";
import { Section, SectionBody } from "@nef/core";
import { PaddedSectionHeader, PaddedSectionHeader2 } from "./tile.front";
import {
  AllOrPending,
  All_Or_Pending,
  MpidOrSymbol,
  Mpid_Or_Security,
  PorOrPvr,
  usePORCacheContext,
} from "../context";
import { useCallback, useMemo } from "react";
import { usePorCountState } from "components/pvRejects/porCountCache";
import { POR_CACHE_FILTER_BY, usePorCacheDispatch } from "components/pvRejects/porCache";
import { useStandardTableDispatch } from "components/standardTable";
import { StandardTables } from "wksConstants";
import { PORModel } from "components/pvRejects/member/constant";

export const Back = ({
  toggle,
  isAllOrPending,
  isMpidOrSymbol,
}: {
  toggle: () => void;
  isAllOrPending: All_Or_Pending;
  isMpidOrSymbol: Mpid_Or_Security;
}) => {
  const { dispatch } = usePORCacheContext();
  const porCountState = usePorCountState();
  const porCacheDispatch = usePorCacheDispatch();
  const tableDispatch = useStandardTableDispatch();

  const { chartData, totalCount, top5 } = useMemo(() => {
    let totalCount = 0;
    let activeCountData: { [key: string]: number };
    if (isMpidOrSymbol === MpidOrSymbol.MPID && isAllOrPending === AllOrPending.ALL) {
      activeCountData = porCountState.counts.mpidCounts;
    } else if (isMpidOrSymbol === MpidOrSymbol.MPID && isAllOrPending === AllOrPending.PENDING) {
      activeCountData = porCountState.pending.mpidCounts;
    } else if (isMpidOrSymbol === MpidOrSymbol.SECURITY && isAllOrPending === AllOrPending.ALL) {
      activeCountData = porCountState.counts.symbolCounts;
    } else if (
      isMpidOrSymbol === MpidOrSymbol.SECURITY &&
      isAllOrPending === AllOrPending.PENDING
    ) {
      activeCountData = porCountState.pending.symbolCounts;
    } else {
      console.error("Invalid POR state found.");
      return { chartData: [], totalCount: 0 };
    }
    const allChartData = Object.entries(activeCountData).map(([key, count]) => {
      totalCount += count;
      return {
        value: count,
        name: key,
      };
    });

    allChartData.sort((a, b) => b.value - a.value);
    const chartData = allChartData.slice(0, Math.min(5, allChartData.length));
    const top5 = (chartData || []).map(data => data.name);
    if (allChartData.length > 5) {
      const otherCount = allChartData.slice(5).reduce((acc, curr) => {
        acc += curr.value;
        return acc;
      }, 0);
      chartData.push({ value: otherCount, name: "Other" });
    }

    return { chartData, totalCount, top5 };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAllOrPending,
    isMpidOrSymbol,
    // keep counts and pending as dependencies
    porCountState.counts,
    porCountState.pending,
    porCountState.counts.mpidCounts,
    porCountState.counts.symbolCounts,
    porCountState.pending.mpidCounts,
    porCountState.pending.symbolCounts,
  ]);

  const onChartClick = useCallback(
    (e: React.MouseEvent | React.FocusEvent, data: any) => {
      const { name } = data;
      tableDispatch({
        type: "DESELECT_ALL_ROWS",
        payload: { table: StandardTables.PV_SUPERVISOR_MONITOR2 },
      });
      if (name === "Other") {
        const attribute = isMpidOrSymbol === "MPID" ? PORModel.MPID : PORModel.SYMBOL;
        porCacheDispatch({
          type: "START_POLLING",
          payload: {
            filterBy: POR_CACHE_FILTER_BY.ALL,
            exclude: { attribute, keys: top5 || [] },
          },
        });
      } else {
        porCacheDispatch({
          type: "START_POLLING",
          payload: {
            filterBy:
              isMpidOrSymbol === "MPID" ? POR_CACHE_FILTER_BY.MPID : POR_CACHE_FILTER_BY.SYMBOL,
            keys: [name],
          },
        });
      }
      dispatch({ type: "SET_SELECTED", payload: { selected: name } });
    },
    [dispatch, isMpidOrSymbol, porCacheDispatch, tableDispatch, top5]
  );

  return (
    <>
      <PaddedSectionHeader>
        <HeaderBox
          toggle={toggle}
          hasAllOrPending={true}
          hasMpidOrSymbol={true}
          isAllOrPending={isAllOrPending}
          isMpidOrSymbol={isMpidOrSymbol}
          total={totalCount}
          title={"POR"}
          isPorOrPvr={PorOrPvr.POR}
        />
      </PaddedSectionHeader>
      <Section>
        <PaddedSectionHeader2>{`Top 5 By ${isMpidOrSymbol}`}</PaddedSectionHeader2>
        <SectionBody>
          <BarChart data={chartData} onClick={onChartClick} />
        </SectionBody>
      </Section>
    </>
  );
};
