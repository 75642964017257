import { PVR_ALERT_TYPE } from "components/settings/pvr/constants";

type POR_WINDOW = {
  alertEndTime: string;
  alertInterval: string;
  endTime: string;
  id: string;
  startTime: string;
};

export enum PorWindowEvent {
  OPEN = "OPEN",
  CLOSE = "CLOSE",
}

export type PVR_ALERT_MODEL = {
  alertId: string;
  alertType: number;
  mpid: string;
  symbol: string;
  symbols: string[];
  requestor: string;
  status: string;
  time: number;
  timestamp: number;
  event: string;
  window: POR_WINDOW;
};

export type PVR_EMAIL_ENTITY = {
  id: string;
  creator: string;
  recipientName: string;
  recipientEmail: string;
  status: string;
  userId: string;
  version: number;
  modifiedBy: string;
  lastUpdate: string;
  new: boolean;
};

export type PVR_CONFIGURATION_ENTITY = {
  id: string;
  mpid: string;
  email: PVR_EMAIL_ENTITY;
  creator: PVR_EMAIL_ENTITY;
  version: number;
  modifiedBy: string;
  lastUpdate: string;
  enabled: boolean;
  new: boolean;
  porStatusAlertActive: boolean;
  pvResubmitAlertActive: boolean;
  pvWindowAlertActive: boolean;
  emailActive: boolean;
  logActive: boolean;
  popupActive: boolean;
};

export const getIdentifierFromAlert = (alert: PVR_ALERT_MODEL) => {
  return `${alert.alertId}`;
};

export const getHashKey = ({ alertType, mpid }: { alertType: number; mpid: string }) => {
  if (alertType === PVR_ALERT_TYPE.POR_WINDOW) {
    mpid = "<ALL>";
  }
  return `${alertType}|${mpid}`;
};
