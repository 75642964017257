import { Mpid_Or_Security, MpidOrSymbol, PorOrPvr } from "../context";
import { DT } from "./dataArray";
import { HeaderBox } from "./tile.container";
import { TileFront } from "./tile.front";

type FrontProps = {
  toggle: () => void;
  isMpidOrSymbol: Mpid_Or_Security;
  mpidArrayCounts: any;
  symbolArrayCounts: any;
  totalRejectCount: number;
};

export const Front: React.FC<FrontProps> = ({
  toggle,
  isMpidOrSymbol,
  mpidArrayCounts,
  symbolArrayCounts,
  totalRejectCount,
}) => {
  return (
    <TileFront
      HeaderBox={
        <HeaderBox
          toggle={toggle}
          hasAllOrPending={false}
          hasMpidOrSymbol={false}
          isMpidOrSymbol={isMpidOrSymbol}
          total={totalRejectCount}
          isPorOrPvr={PorOrPvr.PVR}
          title={"Price Rejects"}
        />
      }
      title1="By Symbol"
      title2="By MPID"
      data1={<DT data={symbolArrayCounts} isMpidOrSymbol={MpidOrSymbol.SECURITY} />}
      data2={<DT data={mpidArrayCounts} isMpidOrSymbol={MpidOrSymbol.MPID} />}
    />
  );
};
