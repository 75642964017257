import { Forms, PVMemberMonitorTop } from "components/fields";
import FieldLoop from "components/fields/fieldLoop";
import React from "react";
import styled from "styled-components";

const FieldWrapper = styled.div`
  grid-column: span 2;
  grid-row: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
`;

export const PorClientMonitorInputs: React.FC = () => {
  return (
    <FieldWrapper>
      <FieldLoop
        isReactFragment={true}
        form={Forms.PV_MONITOR_MEMBER_TOP}
        fields={PVMemberMonitorTop}
        classNames={undefined}
        augmentOnChange={undefined}
        augmentOnCreate={undefined}
        portalRef={undefined}
        isDisabled={undefined}
        containerRef={undefined}
        showLabel={undefined}
      />
    </FieldWrapper>
  );
};
