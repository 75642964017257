export const filterForDataArray = (dataArray: any[], substr: string) => {
  if (substr.length > 0) {
    return dataArray.filter(data => data.label.match(new RegExp(substr, "i")));
  }
  return dataArray;
};

export const REJ_PRICE_OO_RANGE = "!REJ - PRICE OUT OF RANGE";
export const REJ_PRICE_OO_OVERRIDE_RANGE = "!REJ - PRICE OUT OF OVERRIDE RANGE";

export const PV_REJECT_LABELS = {
  [REJ_PRICE_OO_RANGE]: "PRICE OO RANGE",
  [REJ_PRICE_OO_OVERRIDE_RANGE]: "PRICE OO OVERRIDE RANGE",
};

export type PV_REJECT_MESSAGE = typeof REJ_PRICE_OO_RANGE | typeof REJ_PRICE_OO_OVERRIDE_RANGE;

export type PorServiceErrorResponse = { message: string };
