import React, { useMemo } from "react";
import { Indicator } from "@nef/core";
import { useAggregateLimitContext } from "./aggregates";
import { Status } from "wksConstants";

export const LimoLVCIndicator = ({ status, id, prefix }) => {
  const indicatorTooltip = useMemo(() => {
    switch (status) {
      case Status.NO_STATUS:
        return "Feed Status: Awaiting Request";
      case Status.ERROR:
        return "Feed Status: Error";
      case Status.SUCCESS:
        return "Feed Status: Success";
      case Status.WARN:
        return "Feed Status: Partial Success";
      default:
        return "";
    }
  }, [status]);

  return (
    <Indicator id={id} color={status} disabled={status === Status.NO_STATUS}>
      {prefix ? `${prefix} ` : ""}
      {indicatorTooltip}
    </Indicator>
  );
};
