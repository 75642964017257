import { SubtitleList } from "components/notification";

type PorErrorDto = {
  por: POR;
  error: string;
};

export enum PorProcessManyStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  PARTIAL = "PARTIAL",
}

export type PorProcessManyDto = {
  successful: POR[];
  failed: PorErrorDto[];
  status: PorProcessManyStatus;
};

export const getSaveAllSuccessMessage = (numSuccess: number) =>
  `Created ${numSuccess} POR(s) successfully.`;

export const getSaveAllFailedMessage = (numFailed: number) =>
  `Failed to create ${numFailed} POR(s).`;

export const getSaveAllErrorSubtitle = (errorMessages: string[] | undefined) => {
  if (Array.isArray(errorMessages) && errorMessages.length > 0) {
    return (
      <SubtitleList
        messages={errorMessages}
        maxNumShown={2}
        additionalMessageOnMax={`${errorMessages.length - 2} other errors`}
      />
    );
  }
  return "Reason for failures is unknown.";
};

export enum DATE_FILTER {
  ALL = "ALL",
  TODAY = "TODAY",
}

export enum PV_REJECT_TOP {
  mpid = "mpid",
  rejectDate = "rejectDate",
}

interface PvMemberFilter {
  symbol?: string;
  reject?: string;
}

export type PvMemberState = {
  por: POR[];
  [PV_REJECT_TOP.mpid]: string[];
  [PV_REJECT_TOP.rejectDate]: number[];
  filter: PvMemberFilter;
};

export type PvMemberAction =
  | { type: "ADD_PARAM_OPEN_REQ"; payload: POR[] }
  | { type: "SET_FILTER"; payload: PvMemberFilter };

export const PV_REJECT_FIELD_PREFIX = "PV_REJECT_";

export enum PvRejectModel {
  ID = "id",
  ACT_DATE = "actDate",
  ACT_READY_FLAG = "actReadyFlag",
  ACT_TIME = "actTime",
  EXECUTION_DATE = "executionDate",
  EXECUTION_TIME = "executionTime",
  EXECUTION_TIME_NS = "executionTimeNs",
  CONTROL_NUM = "controlNum",
  ADVERTISEMENT_INSTRUCTION = "advertisementInstruction",
  AS_OF_FLAG = "asOfFlag",
  BREAK_STATE = "breakState",
  CARRY_OVER_FLAG = "carryOverFlag",
  CLEARING_FLAG = "clearingFlag",
  CLEARING_PRICE = "clearingPrice",
  CLEARING_STATUS = "clearingStatus",
  CONTRA_BRANCH_SEQ_NUM = "contraBranchSeqNum",
  CONTRA_CAPACITY = "contraCapacity",
  CONTRA_CLEARING_NUM = "contraClearingNum",
  CONTRA_EXEC_ID = "contraExecID",
  CONTRA_GIVEUP_MPID = "contraGiveUpMPID",
  CONTRA_MPID = "contraMPID",
  CONTRA_ORDER_ID = "contraOrderID",
  CONTRA_REF_NUM = "contraReferenceNum",
  CONTRA_SHORT_SALE_IND = "contraShortSaleInd",
  CONTRA_TRADE_REPORT_ID = "contraTradeReportID",
  CORRESPONDENT_MPID = "correspondentMPID",
  ENTRY_I1I2 = "entryI1I2",
  ENTRY_MPID = "entryMPID",
  ENTRY_METHOD_FLAG = "entryMethodFlag",
  ENTRY_SYMBOL = "entrySymbol",
  ENTRY_TRADE_MODS = "entryTradeModifiers",
  EXEC_ID = "execID",
  EXEC_BRANCH_SEQ_NUM = "executingBranchSeqNum",
  EXEC_CAPACITY = "executingCapacity",
  EXEC_CLEARING_NUM = "executingClearingNum",
  EXEC_GIVEUP_MPID = "executingGiveUpMPID",
  EXEC_MPID = "executingMPID",
  FEE = "fee",
  INTENDED_MARKET_FLAG = "intendedMarketFlag",
  LAST_UPDATE_DATE = "lastUpdateDate",
  LAST_UPDATE_I1I2 = "lastUpdateI1I2",
  LAST_UPDATE_TIME = "lastUpdateTime",
  LATE_TRADE_REPORT_FLAG = "lateTradeReportFlag",
  MATCH_CONTROL_NUM = "matchControlNum",
  MATCH_FLAG = "matchFlag",
  MEMO = "memo",
  MOD_2 = "modifier2",
  MOD_2_TIME = "modifier2Time",
  MOD_3 = "modifier3",
  MOD_4 = "modifier4",
  MOD_4_TIME = "modifier4Time",
  ENTRY_MOD_2 = "entryModifier2",
  ENTRY_MOD_3 = "entryModifier3",
  ENTRY_MOD_4 = "entryModifier4",
  NOTIONAL = "notional",
  ORDER_ID = "orderID",
  ORIGINAL_CONTROL_DATE = "originalControlDate",
  ORIGINAL_CONTROL_NUM = "originalControlNum",
  ORIGINAL_MARKET_FLAG = "originalMarketFlag",
  PREV_TRADE_STATUS = "previousTradeStatus",
  PRICE = "price",
  PRICE_OVERRIDE = "priceOverride",
  PRICE_TRADE_DIGIT = "priceTradeDigit",
  PUBLISH_FLAG = "publishFlag",
  QUANTITY = "quantity",
  REF_NUM = "referenceNum",
  REF_REPORTING_VENUE = "referenceReportingVenue",
  RELATED_MARKET_FLAG = "relatedMarketFlag",
  REPORTING_PARTY = "reportingParty",
  REVERSAL_FLAG = "reversalFlag",
  RISK_ELIGIBLE_FLAG = "riskEligibleFlag",
  SECURITY_CLASS = "securityClass",
  SELLER_DAYS = "sellerDays",
  SENT_TO_CLEARING = "sentToClearing",
  SETTLEMENT = "settlement",
  ENTRY_SETTLEMENT = "entrySettlement",
  SIDE = "side",
  SPECIAL = "special",
  SPECIAL_INSTRUCTION = "specialInstruction",
  SPECIAL_TRADE_FLAG = "specialTradeFlag",
  STEP_IN_OUT = "stepInOut",
  SYMBOL = "symbol",
  TRADE_MODS = "tradeModifiers",
  TRADE_REFERENCE_NUM = "tradeReferenceNum",
  TRADE_REPORT_FLAG = "tradeReportFlag",
  TRADE_REPORT_ID = "tradeReportID",
  TRADE_STATUS = "tradeStatus",
  TRADE_THROUGH_EXEMPT = "tradeThroughExempt",
  TRF = "trf",
  WORKSTATION_ACTION = "workstationAction",
  WORKSTATION_ID = "workstationID",
  WORKSTATION_USER_ID = "workstationUserId",
  MOD_2_TIME_NS = "modifier2TimeNs",
  MOD_4_TIME_NS = "modifier4TimeNs",
  STATUS = "status",
  REJECT_COUNT = "rejectCount",
  REJECT_SOURCE = "rejectSource",
  REJECT_TEXT = "rejectText",
  REJECT_STATUS = "rejectStatus",
}

export type PvReject = {
  [PvRejectModel.ID]: number;
  [PvRejectModel.ACT_DATE]: string;
  [PvRejectModel.ACT_READY_FLAG]: string;
  [PvRejectModel.ACT_TIME]: string;
  [PvRejectModel.EXECUTION_DATE]: number;
  [PvRejectModel.EXECUTION_TIME]: number;
  [PvRejectModel.EXECUTION_TIME_NS]: number;
  [PvRejectModel.CONTROL_NUM]: string;
  [PvRejectModel.ADVERTISEMENT_INSTRUCTION]: string;
  [PvRejectModel.AS_OF_FLAG]: string;
  [PvRejectModel.BREAK_STATE]: string;
  [PvRejectModel.CARRY_OVER_FLAG]: string;
  [PvRejectModel.CLEARING_FLAG]: string;
  [PvRejectModel.CLEARING_PRICE]: string;
  [PvRejectModel.CLEARING_STATUS]: string;
  [PvRejectModel.CONTRA_BRANCH_SEQ_NUM]: string;
  [PvRejectModel.CONTRA_CAPACITY]: string;
  [PvRejectModel.CONTRA_CLEARING_NUM]: string;
  [PvRejectModel.CONTRA_EXEC_ID]: string;
  [PvRejectModel.CONTRA_GIVEUP_MPID]: string;
  [PvRejectModel.CONTRA_MPID]: string;
  [PvRejectModel.CONTRA_ORDER_ID]: string;
  [PvRejectModel.CONTRA_REF_NUM]: string;
  [PvRejectModel.CONTRA_SHORT_SALE_IND]: string;
  [PvRejectModel.CONTRA_TRADE_REPORT_ID]: string;
  [PvRejectModel.CORRESPONDENT_MPID]: string;
  [PvRejectModel.ENTRY_I1I2]: string;
  [PvRejectModel.ENTRY_MPID]: string;
  [PvRejectModel.ENTRY_METHOD_FLAG]: string;
  [PvRejectModel.ENTRY_SYMBOL]: string;
  [PvRejectModel.ENTRY_TRADE_MODS]: string;
  [PvRejectModel.EXEC_ID]: string;
  [PvRejectModel.EXEC_BRANCH_SEQ_NUM]: string;
  [PvRejectModel.EXEC_CAPACITY]: string;
  [PvRejectModel.EXEC_CLEARING_NUM]: string;
  [PvRejectModel.EXEC_GIVEUP_MPID]: string;
  [PvRejectModel.EXEC_MPID]: string;
  [PvRejectModel.FEE]: number;
  [PvRejectModel.INTENDED_MARKET_FLAG]: string;
  [PvRejectModel.LAST_UPDATE_DATE]: number;
  [PvRejectModel.LAST_UPDATE_I1I2]: string;
  [PvRejectModel.LAST_UPDATE_TIME]: number;
  [PvRejectModel.LATE_TRADE_REPORT_FLAG]: string;
  [PvRejectModel.MATCH_CONTROL_NUM]: string;
  [PvRejectModel.MATCH_FLAG]: string;
  [PvRejectModel.MEMO]: string;
  [PvRejectModel.MOD_2]: string;
  [PvRejectModel.MOD_2_TIME]: string;
  [PvRejectModel.MOD_3]: string;
  [PvRejectModel.MOD_4]: string;
  [PvRejectModel.MOD_4_TIME]: string;
  [PvRejectModel.ENTRY_MOD_2]: string;
  [PvRejectModel.ENTRY_MOD_3]: string;
  [PvRejectModel.ENTRY_MOD_4]: string;
  [PvRejectModel.NOTIONAL]: number;
  [PvRejectModel.ORDER_ID]: string;
  [PvRejectModel.ORIGINAL_CONTROL_DATE]: string;
  [PvRejectModel.ORIGINAL_CONTROL_NUM]: string;
  [PvRejectModel.ORIGINAL_MARKET_FLAG]: string;
  [PvRejectModel.PREV_TRADE_STATUS]: string;
  [PvRejectModel.PRICE]: number;
  [PvRejectModel.PRICE_OVERRIDE]: boolean;
  [PvRejectModel.PRICE_TRADE_DIGIT]: string;
  [PvRejectModel.PUBLISH_FLAG]: string;
  [PvRejectModel.QUANTITY]: number;
  [PvRejectModel.REF_NUM]: string;
  [PvRejectModel.REF_REPORTING_VENUE]: string;
  [PvRejectModel.RELATED_MARKET_FLAG]: string;
  [PvRejectModel.REPORTING_PARTY]: string;
  [PvRejectModel.REVERSAL_FLAG]: string;
  [PvRejectModel.RISK_ELIGIBLE_FLAG]: string;
  [PvRejectModel.SECURITY_CLASS]: string;
  [PvRejectModel.SELLER_DAYS]: string;
  [PvRejectModel.SENT_TO_CLEARING]: string;
  [PvRejectModel.SETTLEMENT]: string;
  [PvRejectModel.ENTRY_SETTLEMENT]: string;
  [PvRejectModel.SIDE]: string;
  [PvRejectModel.SPECIAL]: string;
  [PvRejectModel.SPECIAL_INSTRUCTION]: string;
  [PvRejectModel.SPECIAL_TRADE_FLAG]: string;
  [PvRejectModel.STEP_IN_OUT]: string;
  [PvRejectModel.SYMBOL]: string;
  [PvRejectModel.TRADE_MODS]: string;
  [PvRejectModel.TRADE_REFERENCE_NUM]: string;
  [PvRejectModel.TRADE_REPORT_FLAG]: string;
  [PvRejectModel.TRADE_REPORT_ID]: string;
  [PvRejectModel.TRADE_STATUS]: string;
  [PvRejectModel.TRADE_THROUGH_EXEMPT]: string;
  [PvRejectModel.TRF]: "1" | "2";
  [PvRejectModel.WORKSTATION_ACTION]: string;
  [PvRejectModel.WORKSTATION_ID]: string;
  [PvRejectModel.WORKSTATION_USER_ID]: string;
  [PvRejectModel.MOD_2_TIME_NS]: string;
  [PvRejectModel.MOD_4_TIME_NS]: string;
  [PvRejectModel.STATUS]: string;
  [PvRejectModel.REJECT_COUNT]: string;
  [PvRejectModel.REJECT_SOURCE]: string;
  [PvRejectModel.REJECT_TEXT]: string;
  [PvRejectModel.REJECT_STATUS]: string;
};

export enum PORStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  EXPIRED = "EXPIRED",
}

export enum PORModel {
  ID = "id",
  SYMBOL = "symbol",
  MPID = "mpid",
  REJECTS = "rejects",
  REQUEST_STATUS = "status",
  SUBSCRIBED = "subscribed",
  REQUESTOR_EMAIL = "requestorEmail",
  REQUESTOR_ID = "requestorId",
  LAST_UDPATE = "lastUpdateDatetime",
}

export interface POR {
  [PORModel.ID]: string;
  [PORModel.SYMBOL]: string;
  [PORModel.MPID]: string;
  [PORModel.REQUEST_STATUS]: PORStatus;
  [PORModel.SUBSCRIBED]: boolean;
  [PORModel.REQUESTOR_EMAIL]: string;
  [PORModel.REQUESTOR_ID]: string;
  [PORModel.LAST_UDPATE]: string;
  [PORModel.REJECTS]: number;
}

export type Reject_By_Count = { count: string; rej: string };
export const REJECTS_BY_COUNT: Reject_By_Count[] = [
  {
    count: "11643",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "7940",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
];

export type Reject_By_Symbol = { count: string; symbol: string; rej: string };
export const REJECTS_BY_SYMBOL: Reject_By_Symbol[] = [
  {
    count: "4479",
    symbol: "BYU",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3451",
    symbol: "BYU",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "1515",
    symbol: "NKGN",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1513",
    symbol: "APLM",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1238",
    symbol: "APLM",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "825",
    symbol: "NKGN",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "667",
    symbol: "CDT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "500",
    symbol: "ICU",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "461",
    symbol: "SFR",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "441",
    symbol: "MGRX",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "422",
    symbol: "CDT",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "389",
    symbol: "VYNE",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "377",
    symbol: "MGRX",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "374",
    symbol: "VYNE",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "311",
    symbol: "SFR",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "306",
    symbol: "BHIL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "235",
    symbol: "LRHC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "220",
    symbol: "BHIL",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "151",
    symbol: "SMFL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "118",
    symbol: "JGGC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "105",
    symbol: "LRHC",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "75",
    symbol: "SMFL",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "74",
    symbol: "JGGC",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "69",
    symbol: "AULT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "64",
    symbol: "NXTP",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "62",
    symbol: "MGTX",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "52",
    symbol: "MOTS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "50",
    symbol: "ZVZZT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "47",
    symbol: "ICU",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "45",
    symbol: "NXTP",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "42",
    symbol: "NVDA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "35",
    symbol: "MGTX",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "32",
    symbol: "AEY",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "30",
    symbol: "ZAPPW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "29",
    symbol: "TSLA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "28",
    symbol: "ZAPPW",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "26",
    symbol: "ALEC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "25",
    symbol: "FRBN",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "24",
    symbol: "FRBN",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "19",
    symbol: "BKYI",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "19",
    symbol: "BXRX",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "18",
    symbol: "AMZN",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "18",
    symbol: "AIRE",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "18",
    symbol: "AEY",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "17",
    symbol: "META",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "16",
    symbol: "RVPH",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "14",
    symbol: "RVPH",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "14",
    symbol: "ALEC",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "14",
    symbol: "MSFT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "14",
    symbol: "BKYI",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "12",
    symbol: "TSM",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "12",
    symbol: "VVOS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "11",
    symbol: "FLFVW",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "11",
    symbol: "VSAT",
    rej: "!REJ - MM NOT ACT AUTHORIZED [149]",
  },
  {
    count: "11",
    symbol: "GOOGL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "11",
    symbol: "BETS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "11",
    symbol: "AAPL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "11",
    symbol: "MOTS",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "10",
    symbol: "UBER",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "10",
    symbol: "LSCC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "10",
    symbol: "FLFVW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "10",
    symbol: "ROSEW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "9",
    symbol: "DCPH",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "8",
    symbol: "BKSY.W",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "7",
    symbol: "DCPH",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "6",
    symbol: "BABA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "5",
    symbol: "BZFDW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "5",
    symbol: "PDD",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "5",
    symbol: "AIRE",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "5",
    symbol: "QQQ",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "5",
    symbol: "GOOG",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "5",
    symbol: "GMM",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "4",
    symbol: "MYSZ",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "4",
    symbol: "NCACW",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "4",
    symbol: "ENPH",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "4",
    symbol: "GVP",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "4",
    symbol: "KERNW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "4",
    symbol: "MU",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "4",
    symbol: "PPYAW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "4",
    symbol: "PYPL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "4",
    symbol: "SE",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "4",
    symbol: "SPY",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "4",
    symbol: "ZM",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "BA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "SSNC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "PEPLW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "TME",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "TMPOW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "PINS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "GTLB",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "DXCM",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "ASML",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "BKSY.WS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "IOT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "ZVV",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "BELFB",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "FNGU",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "SOXX",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    symbol: "TSLA",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "3",
    symbol: "APACW",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "3",
    symbol: "ZVZZT",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "2",
    symbol: "CHTR",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "DIS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "EXPE",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "FTCH",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "GE",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "GECC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "HD",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "JD",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "JNJ",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "KNSL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "LSTR",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "LVWR.W",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "MOS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "NFLX",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "OXY",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "PLAY",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "PLTR",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "RPD",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "SNAP",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "SZZLW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "TNDM",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "TOPS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "TRKAW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "LVWR.W",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "2",
    symbol: "KERNW",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "2",
    symbol: "FFWM",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "2",
    symbol: "WST",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "NEP",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "2",
    symbol: "ENPH",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "2",
    symbol: "OMAB",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "2",
    symbol: "CTLT",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "2",
    symbol: "SFRWW",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "2",
    symbol: "BZFDW",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "2",
    symbol: "TRKAW",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "2",
    symbol: "ABVC",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "2",
    symbol: "AKO.B",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "ALGN",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "AMPH",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "APACW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "ARM",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "AWK",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "BAC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "BAX",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "BDX",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    symbol: "BG",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ALGN",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "1",
    symbol: "ALT",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "1",
    symbol: "BENFW",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "1",
    symbol: "CYA",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "1",
    symbol: "DUNEW",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "1",
    symbol: "F",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "1",
    symbol: "PLTR",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "1",
    symbol: "RIOT",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "1",
    symbol: "ROSEW",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "1",
    symbol: "VVOS",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "1",
    symbol: "ABVC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ACDC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ACMR",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ADOCW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ADTH",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "AEAEW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ALNY",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ALSAR",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ALT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "AMAO",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "AMD",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "AMKR",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "AONC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ARKK",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ARKR",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ATRO",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "AUVI",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "AXLA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "BENFW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "BF.A",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "BIDU",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "BILI",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "BIO",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "BOIL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "BTI",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "BUXX",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "BWMX",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "BWXT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "C",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "CDTTW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "CFLT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "CMCSA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "COEPW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "COLB",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "CORT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "COUR",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "CPT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "CRSP",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "CTVA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "CYA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "DHR",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "DUNEW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "EBMT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "EHC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "EVE",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "EXPD",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "F",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "FFWM",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "FSFG",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "FSLY",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "GDS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "GLSI",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "HAL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "HMNF",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "HOLX",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ILMN",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "INMD",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "INTC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ISRL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ITCI",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "KO",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "KRYS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "LBTYK",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "LMDXW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "LU",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "LYFT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "MAPSW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "MGM",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "MGOL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "MLCO",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "MRNA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "NCACW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "NEE",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "NEWT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "NTRA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "OC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ODP",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ORA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ORGNW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "OXUS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "PCT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "PETS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "PGNY",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "POWL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "PRTA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "PTCT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "RCL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "RCM",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "RGNX",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "RIOT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "RUN",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "SAVE",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "SBRA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "SHOP",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "SNY",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "SPLK",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "SQ",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "SQFTW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "SQLLW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "SRET",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "SWAV",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "TAL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "TDOC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "TLT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "TRCA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "TSCO",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "TTEK",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "UCTT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "VFC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "WINN",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "WSC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "XPEL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "YORW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "YOTAW",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    symbol: "ZI",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
];

export type Reject_By_Mpid = { count: string; mpid: string; rej: string };
export const REJECTS_BY_MPID: Reject_By_Mpid[] = [
  {
    count: "6527",
    mpid: "CDRG",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "4678",
    mpid: "CDRG",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "4129",
    mpid: "NITE",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3199",
    mpid: "NITE",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "273",
    mpid: "UBSS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "254",
    mpid: "VLCT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "228",
    mpid: "DFIN",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "112",
    mpid: "HOOD",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "21",
    mpid: "JPMS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "12",
    mpid: "WABR",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "11",
    mpid: "GSCO",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "8",
    mpid: "MLCO",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "7",
    mpid: "YLPL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "6",
    mpid: "ETMM",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "6",
    mpid: "SBSH",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "6",
    mpid: "SPGS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "5",
    mpid: "BTIG",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "5",
    mpid: "ETMM",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "4",
    mpid: "HRBF",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "4",
    mpid: "MAXM",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "4",
    mpid: "VNDM",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    mpid: "SOHO",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "3",
    mpid: "UBSA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    mpid: "SUFI",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "3",
    mpid: "VNDM",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "3",
    mpid: "UBSA",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "2",
    mpid: "LEHM",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "2",
    mpid: "MAXM",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "2",
    mpid: "BAYC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    mpid: "BNPS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    mpid: "BTIG",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    mpid: "COWN",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    mpid: "LQNT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    mpid: "MIRA",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "2",
    mpid: "PERS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    mpid: "MLIX",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    mpid: "MSCO",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    mpid: "NORT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    mpid: "SOHO",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    mpid: "STFL",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    mpid: "DFIN",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "1",
    mpid: "LATS",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "1",
    mpid: "WCHV",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    mpid: "MSCO",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "1",
    mpid: "SUFI",
    rej: "!REJ - PRICE OUT OF OVERRIDE RANGE",
  },
  {
    count: "1",
    mpid: "BARD",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    mpid: "BERN",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    mpid: "BMOC",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    mpid: "DEAN",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    mpid: "DRIV",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    mpid: "JEFF",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    mpid: "LATS",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
  {
    count: "1",
    mpid: "LGNT",
    rej: "!REJ - PRICE OUT OF RANGE",
  },
];
