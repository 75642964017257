import { NotificationHub } from "@nef/core";
import { ContextProviderProps, DispatchFn, DispatchFnSwitch } from "components/context/constants";
import ContextFactory from "components/context/factory";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { formatUrl } from "utils/js.utils";
import { Status } from "wksConstants";
import { INITIAL_DATA_MODEL, useUserContext } from "components/user";
import { Views } from "viewConstants";
import {
  // getAlertIdentifier,
  getHashKey,
  getIdentifierFromAlert,
  PVR_ALERT_MODEL,
} from "./constants";
import { PROMISE_STATUS } from "../ptra/network";
import { usePvrConfigContext } from "components/settings/pvr/configContext";
import { getAllAlertData } from "./network";
import { PvrAlertBody } from "./pvrAlertBody";
import { PVR_CONFIGURATION_ENTITY } from "components/settings/pvr/fields";

const MAX_POPUPS_DISPLAYED = 2;
const POPUP_TITLE = "Price Reject Override Alert";

export type AlertCacheState = {
  alerts: any;
  readAlerts: any;
  unreadAlerts: any;
  activeAlerts: any[];
  readMap: ReadMap;
  isMarkAllLoading: boolean;
  offset: number;
  status: any; //Status.NO_STATUS,
  makeInitialRequest: boolean;
  requestFailedCount: number;
  hasMadeInitialRequest: boolean;
  isPolling: boolean;
  isRequesting: boolean;
  isLoading: boolean;
  requestAbort: boolean;
};

const DEFAULT_STATE = {
  alerts: {},
  readAlerts: {},
  unreadAlerts: {},
  activeAlerts: [],
  readMap: {},
  isMarkAllLoading: false,
  offset: 0,
  status: Status.NO_STATUS,
  makeInitialRequest: false,
  requestFailedCount: 0,
  hasMadeInitialRequest: false,
  isPolling: false,
  isRequesting: false,
  isLoading: false,
  requestAbort: false,
};

enum READ_STATUS {
  READ = "Read",
  UNREAD = "Unread",
}

type AlertRead = {
  service: string;
  alertId: string;
  userId: string;
  status: READ_STATUS;
};

type ReadMap = { [alertId: string]: boolean };

const evaluateAlertsForLogAndPopups = (
  alertData: PVR_ALERT_MODEL[],
  hashMap: any,
  readMap: ReadMap
) => {
  return alertData.reduce(
    (acc, alert) => {
      const key = getHashKey({
        alertType: alert.alertType,
        mpid: alert.mpid,
      });
      const allKey = getHashKey({
        alertType: alert.alertType,
        mpid: "*", // TOOD: make const
      });
      if (
        hashMap[key]?.[PVR_CONFIGURATION_ENTITY.popupActive] ||
        hashMap[allKey]?.[PVR_CONFIGURATION_ENTITY.popupActive]
      ) {
        acc.popups.push(alert);
      }
      if (
        hashMap[key]?.[PVR_CONFIGURATION_ENTITY.logActive] ||
        hashMap[allKey]?.[PVR_CONFIGURATION_ENTITY.logActive]
      ) {
        // const identifier = getAlertIdentifier(alert.alertId);
        acc.alerts[alert.alertId] = alert;
        if (readMap[alert.alertId]) {
          acc.readAlerts[alert.alertId] = alert;
        } else {
          acc.unreadAlerts[alert.alertId] = alert;
        }
      }
      return acc;
    },
    { popups: [], alerts: {}, readAlerts: {}, unreadAlerts: {} } as {
      popups: any[];
      alerts: any;
      readAlerts: any;
      unreadAlerts: any;
    }
  );
};

const createAlertPopupSubtitle = (popup: any) => {
  return <PvrAlertBody alert={popup} includeMarkAsRead={false} />;
};

const createAlertPopupContent = (alert: PVR_ALERT_MODEL) => {
  if (alert.alertType === 1 && alert.status === "PENDING") {
    return "New Parameter Opening Request";
  }
  if (alert.alertType === 1 && alert.status === "APPROVED") {
    return "Approved Parameter Opening Request";
  }
  if (alert.alertType === 1 && alert.status === "DENIED") {
    return "Denied Parameter Opening Request";
  }
  if (alert.alertType === 1 && alert.status === "EXPIRED") {
    return "Expired Parameter Opening Request";
  }
};

export type AlertCacheAction = { type: any; payload?: any };

const FACTORY = new ContextFactory<AlertCacheState, AlertCacheAction>();

const [DISPATCH_CONTEXT, STATE_CONTEXT] = FACTORY.createContexts(DEFAULT_STATE);

const DISPATCH_FN_SWITCH: DispatchFnSwitch<AlertCacheState, AlertCacheAction> = (
  prevState: AlertCacheState,
  action: AlertCacheAction
) => {
  switch (action.type) {
    case "SET_POLLING": {
      let isPolling = action.payload;
      let makeInitialRequest = false;
      if (isPolling && !prevState.hasMadeInitialRequest) {
        makeInitialRequest = true;
      }
      return { ...prevState, isPolling, makeInitialRequest };
    }
    case "HANDLE_INITIAL_REQUEST": {
      return {
        ...prevState,
        makeInitialRequest: false,
        hasMadeInitialRequest: true,
      };
    }
    case "HANDLE_REQUEST_FAILED": {
      return {
        ...prevState,
        makeInitialRequest: true,
        hasMadeInitialRequest: false,
        requestFailedCount: action.payload,
      };
    }
    case "SET_REQUEST_ABORT": {
      return { ...prevState, requestAbort: action.payload };
    }
    case "SET_LOADING": {
      return { ...prevState, isLoading: action.payload };
    }
    case "SET_REQUESTING": {
      return { ...prevState, isRequesting: action.payload };
    }
    case "SET_REQUEST_STATUS": {
      return { ...prevState, status: action.payload };
    }
    case "SET_DATA": {
      const { alertData, readResult, hashMap } = action.payload;
      const readMap: ReadMap = {};
      if (readResult.status === PROMISE_STATUS.FULFILLED) {
        readResult.value?.forEach((readAlert: AlertRead) => {
          if (readAlert.status === READ_STATUS.READ) {
            readMap[readAlert.alertId] = true;
          }
        });
      } else {
        NotificationHub.send(
          "danger",
          "Error retrieving the read status for Price Reject Override (PRO) Alerts",
          {
            subtitle:
              "This feature will be disabled and all alerts will appear as unread until recovered",
          }
        );
      }
      const { alerts, readAlerts, unreadAlerts } = evaluateAlertsForLogAndPopups(
        alertData,
        hashMap,
        readMap
      );
      const numAlerts = Object.keys(alerts).length;
      const message = `Received ${numAlerts} Price Reject Override (PRO) Alert(s) today`;
      if (prevState.requestFailedCount > 0) {
        NotificationHub.send("success", "Reconnected to Price Reject Override (PRO) Alerts", {
          title: POPUP_TITLE,
          subtitle: message,
        });
      } else if (numAlerts > 0) {
        NotificationHub.send("primary", message, {
          title: POPUP_TITLE,
        });
      }
      return {
        ...prevState,
        alerts,
        readAlerts,
        unreadAlerts,
        readMap,
        requestFailedCount: 0,
        status: Status.SUCCESS,
        offset: alertData.length,
      };
    }
    case "ADD_DATA": {
      const { data, hashMap } = action.payload;
      const { alerts, unreadAlerts, popups } = evaluateAlertsForLogAndPopups(
        data,
        hashMap,
        prevState.readMap
      );
      if (popups.length > MAX_POPUPS_DISPLAYED) {
        NotificationHub.send(
          "primary",
          `Recieved ${popups.length} Price Reject Override (PRO) Alerts configured for pop-ups`,
          {
            title: POPUP_TITLE,
            subtitle: `A max of ${MAX_POPUPS_DISPLAYED} pop-ups can be displayed at once`,
          }
        );
      } else {
        popups.forEach((popup: any) => {
          const color = "primary";
          NotificationHub.send(color, createAlertPopupContent(popup), {
            subtitle: createAlertPopupSubtitle(popup),
            title: POPUP_TITLE,
          });
        });
      }
      return {
        ...prevState,
        offset: prevState.offset + data.length,
        readAlerts: prevState.readAlerts,
        alerts: { ...prevState.alerts, ...alerts },
        unreadAlerts: { ...prevState.unreadAlerts, ...unreadAlerts },
      };
    }
    case "SET_READ": {
      const newReadMap = { ...prevState.readMap };
      const newReadAlerts = { ...prevState.readAlerts };
      const newUnreadAlerts = { ...prevState.unreadAlerts };
      action.payload.forEach((alert: AlertRead) => {
        const alertId = alert.alertId;
        newReadMap[alertId] = alert.status === READ_STATUS.READ;
        delete newUnreadAlerts[alertId];
        if (prevState.alerts[alertId]) {
          newReadAlerts[alertId] = prevState.alerts[alertId];
        } else {
          console.error("PVR Read ID not found: " + alertId);
        }
      });
      return {
        ...prevState,
        readMap: newReadMap,
        readAlerts: newReadAlerts,
        unreadAlerts: newUnreadAlerts,
      };
    }
    case "SET_UNREAD": {
      const newReadMap = { ...prevState.readMap };
      const newReadAlerts = { ...prevState.readAlerts };
      const newUnreadAlerts = { ...prevState.unreadAlerts };
      action.payload.forEach((alert: PVR_ALERT_MODEL) => {
        const alertId = getIdentifierFromAlert(alert);
        delete newReadMap[alertId];
        delete newReadAlerts[alertId];
        if (prevState.alerts[alertId]) {
          newUnreadAlerts[alertId] = prevState.alerts[alertId];
        } else {
          console.error("PVR Unread ID not found: " + alertId);
        }
      });
      return {
        ...prevState,
        readMap: newReadMap,
        readAlerts: newReadAlerts,
        unreadAlerts: newUnreadAlerts,
      };
    }
    case "SET_ALL_UNREAD": {
      const newReadMap = { ...prevState.readMap };
      const newReadAlerts = { ...prevState.readAlerts };
      const newUnreadAlerts = { ...prevState.unreadAlerts };
      action.payload.forEach((alert: any) => {
        const alertId = alert.id;
        delete newReadMap[alertId];
        delete newReadAlerts[alertId];
        if (prevState.alerts[alertId]) {
          newUnreadAlerts[alertId] = prevState.alerts[alertId];
        } else {
          console.error("PVR Unread ID not found: " + alertId);
        }
      });
      return {
        ...prevState,
        readMap: newReadMap,
        readAlerts: newReadAlerts,
        unreadAlerts: newUnreadAlerts,
      };
    }
    case "SET_MARK_ALL_LOADING": {
      return { ...prevState, isMarkAllLoading: action.payload };
    }
    case "SET_ACTIVE_ALERTS": {
      return { ...prevState, activeAlerts: action.payload };
    }
    default:
      return { ...prevState };
  }
};

const DISPATCH_FN = FACTORY.createDispatchFn<AlertCacheState, AlertCacheAction>(DISPATCH_FN_SWITCH);

interface AlertCacheProviderProps extends ContextProviderProps {}

export const AlertCacheProvider: React.FC<AlertCacheProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<DispatchFn<AlertCacheState, AlertCacheAction>>(
    DISPATCH_FN,
    DEFAULT_STATE
  );
  const [abort, setAbort] = useState(new AbortController());
  const [userData] = useUserContext();
  const [configs] = usePvrConfigContext();

  useEffect(() => {
    if (state.requestAbort && abort) {
      abort.abort();
      dispatch({ type: "SET_REQUEST_ABORT", payload: false });
    }
  }, [abort, state.requestAbort]);

  const pollAfterTimeout = useCallback(() => {
    setTimeout(() => {
      dispatch({ type: "SET_REQUESTING", payload: true });
    }, 7500);
  }, []);

  const getPtraAlertData = useCallback(
    (resetFlag: boolean) => {
      const getPtraAlertError = () => {
        console.warn(`An error retrieving PVR alerts happened [${new Date()}]`);
        const actions = [
          {
            type: "SET_LOADING",
            payload: false,
          },
          { type: "HANDLE_REQUEST_FAILED", payload: state.requestFailedCount + 1 },
          {
            type: "SET_REQUEST_STATUS",
            payload: Status.ERROR,
          },
        ];
        if (state.status !== Status.ERROR) {
          NotificationHub.send(
            "danger",
            "An error occurred while retrieving Price Reject Override (PRO) Alert data."
          );
        }
        dispatch(actions);
      };

      const getPtraAlertCallback = (json: any) => {
        pollAfterTimeout();
        if (json?.alerts?.length > 0) {
          dispatch({
            type: "ADD_DATA",
            payload: { data: json.alerts, hashMap: configs.hashMap },
          });
        }
      };

      const abortController = new AbortController();
      setAbort(abortController);

      if (resetFlag) {
        getAllAlertData(abortController.signal).then(
          (results: any) => {
            const cacheResult = results[0];
            const readResult = results[1];
            if (cacheResult.status === PROMISE_STATUS.FULFILLED) {
              dispatch([
                {
                  type: "SET_DATA",
                  payload: {
                    alertData: cacheResult.value.alerts,
                    readResult,
                    hashMap: configs.hashMap,
                  },
                },
                {
                  type: "SET_REQUEST_STATUS",
                  payload: Status.SUCCESS,
                },
              ]);
              pollAfterTimeout();
            } else {
              getPtraAlertError();
            }
          },
          (reject: any) => {
            console.warn({ reject });
            getPtraAlertError();
          }
        );
      } else {
        doFetchWrapper(
          formatUrl(process.env.REACT_APP_URL_PVR_ALERT_SERVICE, `alert/subscription/userAlerts`),
          {
            method: "post",
            mode: "cors",
            signal: abortController.signal,
            headers: getHeaders(),
            body: JSON.stringify({ offset: state.offset }),
          },
          getPtraAlertCallback,
          getPtraAlertError,
          undefined,
          undefined
          // MAX_RETRY
        );
      }
    },
    [state.requestFailedCount, state.status, state.offset, pollAfterTimeout, configs.hashMap]
  );

  useEffect(() => {
    if (
      userData.allowed.views[Views.PVR_ALERTS] &&
      userData[INITIAL_DATA_MODEL.config] &&
      configs.hashMap
    ) {
      dispatch({
        type: "SET_POLLING",
        payload: true,
      });
    }
  }, [configs.hashMap, userData]);

  useEffect(() => {
    if (!state.hasMadeInitialRequest && state.makeInitialRequest && state.isPolling) {
      const initialRequest = () => {
        dispatch([{ type: "SET_LOADING", payload: true }, { type: "HANDLE_INITIAL_REQUEST" }]);
        getPtraAlertData(true);
      };
      if (state.requestFailedCount > 0) {
        setTimeout(() => {
          initialRequest();
        }, 7500);
      } else {
        initialRequest();
      }
    }
  }, [
    state.hasMadeInitialRequest,
    state.makeInitialRequest,
    getPtraAlertData,
    state.requestFailedCount,
    state.isPolling,
  ]);

  useEffect(() => {
    if (state.isPolling && state.isRequesting) {
      dispatch({ type: "SET_REQUESTING", payload: false });
      getPtraAlertData(false);
    }
  }, [state.isPolling, state.isRequesting, getPtraAlertData]);

  return (
    <DISPATCH_CONTEXT.Provider value={dispatch}>
      <STATE_CONTEXT.Provider value={state}>{children}</STATE_CONTEXT.Provider>
    </DISPATCH_CONTEXT.Provider>
  );
};

export const useAlertCacheDispatch =
  FACTORY.createContextHook<React.Dispatch<AlertCacheAction | AlertCacheAction[]>>(
    DISPATCH_CONTEXT
  );
export const useAlertCacheState = FACTORY.createContextHook<AlertCacheState>(STATE_CONTEXT);
